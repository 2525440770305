import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Adjust = (props: IconProps) => (
  <Icon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15.3496 2.14076V1" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.3496 16.9998V8.63501" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 8.4492V1" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 17V14.8018" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.64941 4.62791V1" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.64941 17V11.3574" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M2.64947 10.8409C3.66616 10.8409 4.49036 10.0168 4.49036 9.00006C4.49036 7.98337 3.66616 7.15918 2.64947 7.15918C1.63278 7.15918 0.808594 7.98337 0.808594 9.00006C0.808594 10.0168 1.63278 10.8409 2.64947 10.8409Z"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99908 14.5248C10.0158 14.5248 10.84 13.7006 10.84 12.6839C10.84 11.6672 10.0158 10.843 8.99908 10.843C7.98239 10.843 7.1582 11.6672 7.1582 12.6839C7.1582 13.7006 7.98239 14.5248 8.99908 14.5248Z"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3497 8.30945C16.3664 8.30945 17.1906 7.48526 17.1906 6.46857C17.1906 5.45188 16.3664 4.62769 15.3497 4.62769C14.333 4.62769 13.5088 5.45188 13.5088 6.46857C13.5088 7.48526 14.333 8.30945 15.3497 8.30945Z"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
