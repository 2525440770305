import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import FormWrapper from '../common/FormWrapper';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useUserStore } from '../../store/user';
import React, { useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import configureAxios from '../../axiosClient';
import { dispatchSubscriptionToAnalytics } from '../../analytics';
import { usePlanStore } from '../../store/plan';
// import { dispatchSubscriptionToAnalytics } from '../../analytics';

type EnterpriseContactModalFormValues = {
  name: string;
  number: string;
  company: string;
  email: string;
  message: string;
  checkBoxOne: boolean;
  checkBoxTwo: boolean;
};
interface EnterpriseContactModalProps {
  enterpriseContactActive: boolean;
  setEnterpriseContactActive: (state: boolean) => void;
}

const axiosClient = configureAxios();

function EnterpriseContactModal({ enterpriseContactActive, setEnterpriseContactActive }: EnterpriseContactModalProps) {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { organisation, user } = useUserStore();
  const { currentPlan } = usePlanStore();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EnterpriseContactModalFormValues>();

  const onSubmit: SubmitHandler<EnterpriseContactModalFormValues> = (data) => {
    setIsSubmitting(true);
    const userOrgId = organisation.organizationId;
    axiosClient
      .post(`org/${userOrgId}/email/enterprise`, {
        name: data.name,
        number: data.number,
        email: data.email,
        company: data.company,
        message: data.message,
      })
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          setFormSubmitted(true);
          toast({
            title: `Your request was sent successfully.`,
            status: 'success',
          });
          dispatchSubscriptionToAnalytics('upgrade_subscription', {
            current_plan: 'Enterprise Plan',
            previous_plan: currentPlan.name,
            subscription_price: '',
            organisation_id: organisation.organizationId,
            organisation_user_id: user.id,
            subscription_interval: '',
          });
        } else {
          toast({
            title: `Your request was failed.`,
            status: 'error',
          });
        }
        setIsSubmitting(false);
      })
      .catch((err: unknown) => {
        console.log(err);
        setIsSubmitting(false);
      });
  };

  const close = () => {
    setEnterpriseContactActive(false);
    setFormSubmitted(false);
    reset();
  };

  return (
    <Modal isOpen={enterpriseContactActive} onClose={close} isCentered>
      <ModalOverlay />
      <ModalContent
        minW={['600px']}
        height={['auto']}
        padding={['20px']}
        borderRadius="16px"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <CloseIcon
          position="absolute"
          boxSize={[4]}
          cursor="pointer"
          color="#D6DBE4"
          _hover={{ color: 'black' }}
          transition="0.2s all"
          right={['20px']}
          top={['20px']}
          onClick={close}
        />
        <ModalBody>
          <Collapse in={!formSubmitted} animateOpacity>
            <Box display="flex" flexDirection="column" alignItems="center" gap={5}>
              <Text
                color="#08101D"
                fontFamily="Metal"
                fontSize={['35px']}
                lineHeight={['35px']}
                textAlign="center"
                textTransform="uppercase"
              >
                Enterprise Plan
              </Text>

              <Box>
                <Text fontFamily="Normal" fontSize="15px" color="rgba(8, 16, 29, 1)" textAlign="center">
                  Contact Sales for Enterprise Plan inquiries
                </Text>
                <Text fontFamily="Normal" fontSize="12px" color="rgba(8, 16, 29, 1)" textAlign="center">
                  Book a demo to discuss custom solutions for your organization or agency.
                </Text>
              </Box>

              <FormWrapper onSubmit={handleSubmit(onSubmit)} name="enterprise_form">
                <Box
                  w={['100%']}
                  height={['auto']}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={5}
                >
                  <Box display="flex" gap={5} width="100%" flexWrap="wrap">
                    <FormControl isInvalid={errors.name ? true : false} isRequired flex="1 1 45%">
                      <FormLabel color="rgba(127, 139, 158, 1)" fontFamily="Normal" fontSize="12px" margin="0px">
                        Name
                      </FormLabel>
                      <Input
                        id="name"
                        placeholder="enter name"
                        color="#58677E"
                        borderBottom="1px solid #D6DBE4"
                        variant="flushed"
                        fontFamily="Normal"
                        fontSize={['14px', '14px', '15px']}
                        size="md"
                        _placeholder={{ color: '#7F8B9E' }}
                        errorBorderColor="#EA1A4C"
                        {...register('name', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />
                      <FormErrorMessage color="#EA1A4C" mt={['4px']} fontSize="13px" fontFamily="Normal">
                        {errors.name && errors.name.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.number ? true : false} flex="1 1 45%">
                      <FormLabel color="rgba(127, 139, 158, 1)" fontFamily="Normal" fontSize="12px" margin="0px">
                        Phone Number
                      </FormLabel>
                      <Input
                        id="number"
                        placeholder="enter number"
                        color="#58677E"
                        borderBottom="1px solid #D6DBE4"
                        variant="flushed"
                        fontFamily="Normal"
                        fontSize={['14px', '14px', '15px']}
                        size="md"
                        _placeholder={{ color: '#7F8B9E' }}
                        errorBorderColor="#EA1A4C"
                        {...register('number', {
                          required: false,
                        })}
                      />
                      <FormErrorMessage color="#EA1A4C" mt={['4px']} fontSize="13px" fontFamily="Normal">
                        {errors.number && errors.number.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.company ? true : false} isRequired flex="1 1 45%">
                      <FormLabel color="rgba(127, 139, 158, 1)" fontFamily="Normal" fontSize="12px" margin="0px">
                        Company
                      </FormLabel>
                      <Input
                        id="company"
                        placeholder="enter company"
                        color="#58677E"
                        borderBottom="1px solid #D6DBE4"
                        variant="flushed"
                        fontFamily="Normal"
                        fontSize={['14px', '14px', '15px']}
                        size="md"
                        _placeholder={{ color: '#7F8B9E' }}
                        errorBorderColor="#EA1A4C"
                        {...register('company', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />
                      <FormErrorMessage color="#EA1A4C" mt={['4px']} fontSize="13px" fontFamily="Normal">
                        {errors.company && errors.company.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.email ? true : false} isRequired flex="1 1 45%">
                      <FormLabel color="rgba(127, 139, 158, 1)" fontFamily="Normal" fontSize="12px" margin="0px">
                        Work Email address
                      </FormLabel>
                      <Input
                        id="email"
                        placeholder="enter email"
                        color="#58677E"
                        borderBottom="1px solid #D6DBE4"
                        variant="flushed"
                        fontFamily="Normal"
                        fontSize={['14px', '14px', '15px']}
                        size="md"
                        _placeholder={{ color: '#7F8B9E' }}
                        errorBorderColor="#EA1A4C"
                        {...register('email', {
                          required: 'This is required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Invalid Email',
                          },
                        })}
                      />
                      <FormErrorMessage color="#EA1A4C" mt={['4px']} fontSize="13px" fontFamily="Normal">
                        {errors.email && errors.email.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.message ? true : false} isRequired flex="1 1 100%">
                      <FormLabel color="rgba(127, 139, 158, 1)" fontFamily="Normal" fontSize="12px">
                        Message
                      </FormLabel>
                      <Textarea
                        id="message"
                        placeholder="enter message"
                        minHeight="100px"
                        maxHeight="200px"
                        color="#58677E"
                        border="1px solid rgba(211, 187, 138, 1) !important"
                        _focusVisible={{
                          border: 'none',
                        }}
                        borderRadius="16px"
                        variant="outline"
                        fontFamily="Normal"
                        fontSize={['14px', '14px', '15px']}
                        errorBorderColor="#EA1A4C"
                        _placeholder={{ color: '#7F8B9E' }}
                        {...register('message', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />

                      <FormErrorMessage color="#EA1A4C" mt={['4px']} fontSize="13px" fontFamily="Normal">
                        {errors.message && errors.message.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>

                  <Box>
                    <FormControl isInvalid={errors.checkBoxOne ? true : false} isRequired flex="1 1 100%">
                      <Checkbox
                        id="checkBoxOne"
                        colorScheme="blue"
                        {...register('checkBoxOne', {
                          required: true,
                        })}
                      >
                        <Text fontFamily="Normal" fontSize="11px" color="rgba(88, 103, 126, 1)" textAlign="center">
                          By checking this box you agree to the Emperia’s&nbsp;
                          <Link
                            target="_blank"
                            href="https://emperiavr.com/wp-content/uploads/2022/08/terms-of-use-2022.pdf"
                            textDecoration="underline"
                          >
                            Terms of Service
                          </Link>
                          &nbsp;and&nbsp;
                          <Link
                            target="_blank"
                            href="http://emperiavr.com/wp-content/uploads/2022/08/privacy-policy-2022.pdf"
                            textDecoration="underline"
                          >
                            Privacy Policy
                          </Link>
                        </Text>
                      </Checkbox>
                    </FormControl>
                    <FormControl isInvalid={errors.checkBoxTwo ? true : false} isRequired flex="1 1 100%">
                      <Checkbox
                        id="checkBoxTwo"
                        colorScheme="blue"
                        {...register('checkBoxTwo', {
                          required: true,
                        })}
                      >
                        <Text fontFamily="Normal" fontSize="11px" color="rgba(88, 103, 126, 1)" textAlign="center">
                          By checking this box you agree to receive electronic communication from Emperia
                        </Text>
                      </Checkbox>
                    </FormControl>
                  </Box>

                  <Button
                    textTransform="uppercase"
                    width={['80%', '80%', '300px']}
                    variant="outline"
                    color="#08101D"
                    background="white"
                    fontFamily="Metal"
                    fontSize={['14px', '14px', '14px']}
                    borderRadius="30px"
                    border="1px solid #D6DBE4"
                    _hover={{
                      bg: '#D3BB8A',
                      borderColor: '#D3BB8A',
                    }}
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Send
                  </Button>
                </Box>
              </FormWrapper>

              <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                <Button
                  fontSize={['11px']}
                  variant="link"
                  fontFamily="Normal"
                  textAlign="center"
                  mt={['15px']}
                  onClick={close}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Collapse>
          <Collapse in={formSubmitted} animateOpacity>
            <Box display="flex" flexDirection="column" alignItems="center" gap={5}>
              <Text fontFamily="Bold" fontSize="15px" color="rgba(6, 255, 71, 1)" textAlign="center">
                Completed
              </Text>

              <Box>
                <Text fontFamily="Bold" fontSize="15px" color="rgba(8, 16, 29, 1)" textAlign="center">
                  Your inquiry has been sent.
                </Text>
                <Text fontFamily="Normal" fontSize="15px" color="rgba(8, 16, 29, 1)" textAlign="center">
                  Our sales team will be in touch shortly to discuss the Enterprise Plan. In the meantime, feel free to
                  explore the platform in <span style={{ fontFamily: 'Bold' }}>Free Plan</span> mode.
                </Text>
              </Box>

              <Button
                textTransform="uppercase"
                width={['80%', '80%', '300px']}
                variant="outline"
                color="#08101D"
                background="white"
                fontFamily="Metal"
                fontSize={['14px', '14px', '14px']}
                borderRadius="30px"
                border="1px solid #D6DBE4"
                _hover={{
                  bg: '#D3BB8A',
                  borderColor: '#D3BB8A',
                }}
                onClick={close}
              >
                Continue
              </Button>
            </Box>
          </Collapse>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EnterpriseContactModal;
