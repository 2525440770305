import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const GoldStar = (props: IconProps) => (
  <Icon width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.94305 7.05704L10.5 2.26525L12.0569 7.05704C12.231 7.59267 12.7301 7.95532 13.2933 7.95532H18.3317L14.2556 10.9168C13.7999 11.2479 13.6093 11.8346 13.7833 12.3703L15.3403 17.1621L11.2641 14.2006C10.8085 13.8695 10.1915 13.8695 9.73588 14.2006L5.65973 17.1621L7.21668 12.3703C7.39072 11.8346 7.20006 11.2479 6.74443 10.9168L2.66828 7.95532H7.70668C8.26987 7.95532 8.76902 7.59267 8.94305 7.05704ZM10.595 1.97277L10.5951 1.97254L10.595 1.97277Z"
      strokeWidth="1.4"
      strokeLinejoin="bevel"
    />
  </Icon>
);
