import { Box, Button, Image, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text } from '@chakra-ui/react';
import { Settings } from '../../Icons/Settings';
import { NavLink, useNavigate } from 'react-router-dom';
import { useUserStore } from '../../store/user';
import DummyUser from '../../assets/image/DummyUser.png';
import { useAuthStore } from '../../store/auth';
import useRoleAuthorization from '../../utils/useRoleAuthorization';
import React from 'react';
import configureAxios from '../../axiosClient';
import { useExperiencesStore } from '../../store/experiences';
import { usePlanStore } from '../../store/plan';

const axiosClient = configureAxios();

function UserDropdown() {
  const navigate = useNavigate();
  const hasAccess = useRoleAuthorization();
  const { resetPlans } = usePlanStore();
  const { resetExperiences } = useExperiencesStore();
  const { user, roles, resetUser } = useUserStore();
  const { resetAuth } = useAuthStore();

  const logOut = () => {
    resetAuth();
    resetUser();
    resetExperiences();
    resetPlans();

    axiosClient
      .get(`auth/logout`)
      .then(() => {
        console.log('logged out');
      })
      .catch((err: unknown) => {
        console.log(err);
      });
  };

  return (
    <Popover placement="bottom-end" returnFocusOnClose={false} closeOnBlur={true}>
      <PopoverTrigger>
        <Image loading="lazy" src={DummyUser} height={['35px']} width={['35px']} borderRadius="100%" cursor="pointer" />
      </PopoverTrigger>
      <PopoverContent
        borderRadius="16px"
        background="#FFF"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        backdropFilter="blur(12px)"
        width={['350px']}
        height={['auto']}
      >
        <PopoverBody
          height={['inherit']}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          padding={['20px']}
        >
          <Box
            borderRadius="16px"
            border="1px solid #D6DBE4"
            boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.25)"
            padding={['15px']}
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center" gap={1}>
              <Image
                src={DummyUser}
                height={['40px']}
                width={['40px']}
                borderRadius="100%"
                cursor="pointer"
                mr={['10px']}
              />

              <Box width={['auto']}>
                <Box display="flex" justifyContent="flex-start" alignItems="center" width={['inherit']} gap={2}>
                  <Text color="#08101D" fontFamily="Normal" fontSize={['15px']}>
                    {user.firstName + ' ' + user.lastName}
                  </Text>
                  <Text color="#08101D" fontFamily="Normal" fontSize={['11px']}>
                    {roles?.includes('owner')
                      ? 'Owner'
                      : roles?.includes('admin')
                      ? 'Admin'
                      : roles?.includes('member') && ' Member'}
                  </Text>
                </Box>
                <Text color="#08101D" fontFamily="Normal" fontSize={['14px']} width={['inherit']}>
                  {user.email}
                </Text>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent={hasAccess ? 'space-between' : 'flex-end'}
            alignItems="center"
            mt={['20px']}
          >
            {hasAccess && (
              <NavLink to="/billing" style={{ height: 'auto' }}>
                <Button
                  variant="link"
                  leftIcon={<Settings boxSize={[4]} />}
                  color="#08101D"
                  fontFamily="Normal"
                  fontSize={['11px']}
                >
                  Organization Settings
                </Button>
              </NavLink>
            )}

            <Button
              variant="outline"
              color="#08101D"
              fontFamily="Normal"
              fontSize={['11px']}
              borderRadius="30px"
              border="1px solid #08101D"
              height={['30px']}
              onClick={() => {
                navigate('/login');
                logOut();
              }}
            >
              Log out
            </Button>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default UserDropdown;
