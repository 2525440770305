import { useEffect, useState } from 'react';
import { Box, Text, Button, Progress, useToast, Skeleton } from '@chakra-ui/react';
import BillingOption from './BillingOption';
// import { GraphIcon } from '../../Icons/GraphIcon';
import configureAxios from '../../axiosClient';
import { useUserStore } from '../../store/user';
import LeftInfoPanel from './LeftInfoPanel';
import { usePlanStore } from '../../store/plan';
import React from 'react';

const axiosClient = configureAxios();

type Usage = {
  storage: {
    used: number;
    limit: number;
    unit_price: number;
  };
  bandwidth: {
    used: number;
    limit: number;
    unit_price: number;
  };
};

interface OrganisationInfoProps {
  numberOfMembers: string;
}

function OrganisationInfo({ numberOfMembers }: OrganisationInfoProps) {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });

  const { organisation } = useUserStore();
  const { setShowPlans } = usePlanStore();

  const [loadingUsage, setLoadingUsage] = useState<boolean>(true);
  const [usage, setUsage] = useState<Usage>({
    storage: {
      used: 0,
      limit: 0,
      unit_price: 0,
    },
    bandwidth: {
      used: 0,
      limit: 0,
      unit_price: 0,
    },
  });

  useEffect(() => {
    const userOrgId = organisation.organizationId;

    setLoadingUsage(true);
    if (organisation.organizationId.length > 0)
      axiosClient
        .get(`org/${userOrgId}/usage/read`)
        .then((res) => {
          setLoadingUsage(false);
          const isStatusOk = res.data.status === 'OK';
          if (res.data.status === 'LOGOUT') {
          } else if (isStatusOk) {
            setUsage(res.data.data);
          } else {
            toast({
              title: `There was an error fetching usage details`,
              status: 'error',
            });
          }
        })
        .catch((err: unknown) => {
          console.log(err);
        });
  }, []);

  const storageUsed = usage.storage.used;
  const storageLimit = usage.storage.limit;
  const storageUnitPrice = usage.storage.unit_price;
  const additionalStorageUsed = storageUsed > storageLimit ? storageUsed - storageLimit : 0;
  const storageDivider = storageUsed / storageLimit || 0;
  const storagePercentageUsed = storageDivider * 100;
  const storagePercentageUsedFixed = storagePercentageUsed.toFixed(0);

  const bandwidthUsed = usage.bandwidth.used;
  const bandwidthLimit = usage.bandwidth.limit;
  const bandwidthUnitPrice = usage.bandwidth.unit_price;
  const additionalBandwidthUsed = bandwidthUsed > bandwidthLimit ? bandwidthUsed - bandwidthLimit : 0;
  const bandwidthDivider = bandwidthUsed / bandwidthLimit || 0;
  const bandwidthPercentageUsed = bandwidthDivider * 100;
  const bandwidthPercentageUsedFixed = bandwidthPercentageUsed.toFixed(0);

  const isEnterpriseStorage = storageLimit === 0;
  const isEnterpriseBandwidth = bandwidthLimit === 0;

  return (
    <Box flex="1" display="flex" padding="40px" gap={8}>
      <LeftInfoPanel numberOfMembers={numberOfMembers} />
      <Box flex="1" gap={10} display="flex" flexDirection="column">
        <Box w={['100%']} height={['auto']} display="flex" flexDirection="column" alignItems="center" gap={5}>
          <Skeleton
            width="inherit"
            startColor="#29303C"
            endColor="transparent"
            isLoaded={!loadingUsage}
            borderRadius="16px"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={['16px']}
              width={['inherit']}
              borderRadius="16px"
              border="1px solid rgba(242, 243, 245, 0.14)"
            >
              <Text fontSize={['15px']} minW="150px" fontWeight="900" fontFamily="Normal">
                Data Storage
              </Text>

              <Box display="flex" justifyContent="center" alignItems="center" gap={10}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <Box display="flex" alignItems="center" gap={2}>
                    <Text
                      fontSize={['15px']}
                      color={storagePercentageUsed > 100 && !isEnterpriseStorage ? 'red.500' : 'green.500'}
                      fontWeight="900"
                      fontFamily="Bold"
                    >
                      {`${storageUsed > 0 ? storageUsed.toFixed(2) : storageUsed} GB`}
                    </Text>
                  </Box>
                  <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                    {isEnterpriseStorage ? `/ Unlimited` : `/ ${storageLimit}GB + ${additionalStorageUsed}GB Add-on`}
                  </Text>

                  <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                    {isEnterpriseStorage ? `used` : `${storagePercentageUsedFixed}% used`}
                  </Text>
                </Box>

                {!isEnterpriseStorage && (
                  <Box display="flex" flexDirection="column" justifyContent="center" gap={1}>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Text
                        fontSize={['12px']}
                        color={storagePercentageUsed > 100 ? 'red.500' : 'green.500'}
                        fontWeight="900"
                        fontFamily="Bold"
                      >
                        {`${storageUsed > 0 ? storageUsed.toFixed(2) : storageUsed} GB`}
                      </Text>
                      <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                        {`(${storagePercentageUsedFixed})%`}
                      </Text>
                    </Box>
                    <Progress
                      borderRadius="30px"
                      size="sm"
                      value={storagePercentageUsed || 0}
                      colorScheme={storagePercentageUsed > 100 ? 'red' : 'green'}
                      bg="#58677E"
                    />
                    <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                      {`of ${storageLimit}GB + ${additionalStorageUsed}GB Add-on`}
                    </Text>
                  </Box>
                )}

                {/* <Box display="flex" flexDirection="column" justifyContent="center">
                  <Text fontSize={['15px']} fontWeight="900" fontFamily="Bold">
                    1.5 GB
                  </Text>
                  <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                    This Month
                  </Text>
                  <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                    30% used
                  </Text>
                </Box> */}

                {storageUnitPrice > 0 && (
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                    <Text fontSize={['15px']} color="#D3BB8A" fontWeight="900" fontFamily="Bold">
                      {`$${storageUnitPrice} /GB`}
                    </Text>
                    <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                      Add-on Rate
                    </Text>
                    <Button variant="link" fontSize={['12px']} color="#D3BB8A" fontFamily="Semi">
                      Buy Now
                    </Button>
                  </Box>
                )}
              </Box>

              <Box>{/* <GraphIcon boxSize={[6]} /> */}</Box>
            </Box>
          </Skeleton>

          <Skeleton
            width="inherit"
            startColor="#29303C"
            endColor="transparent"
            isLoaded={!loadingUsage}
            borderRadius="16px"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={['16px']}
              width={['inherit']}
              borderRadius="16px"
              border="1px solid rgba(242, 243, 245, 0.14)"
            >
              <Text fontSize={['15px']} minW="150px" fontWeight="900" fontFamily="Normal">
                Bandwidth
              </Text>

              <Box display="flex" justifyContent="center" alignItems="center" gap={10}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <Text
                    fontSize={['15px']}
                    color={bandwidthPercentageUsed > 100 && !isEnterpriseBandwidth ? 'red.500' : 'green.500'}
                    fontWeight="900"
                    fontFamily="Bold"
                  >
                    {`${bandwidthUsed > 0 ? bandwidthUsed.toFixed(2) : bandwidthUsed} GB`}
                  </Text>
                  <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                    {isEnterpriseBandwidth
                      ? `/ Unlimited`
                      : `/ ${bandwidthLimit}GB + ${additionalBandwidthUsed}GB Add-on`}
                  </Text>
                  <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                    {isEnterpriseBandwidth ? `used` : `${bandwidthPercentageUsedFixed}% used`}
                  </Text>
                </Box>

                {!isEnterpriseBandwidth && (
                  <Box display="flex" flexDirection="column" justifyContent="center" gap={1}>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Text
                        fontSize={['12px']}
                        color={bandwidthPercentageUsed > 100 ? 'red.500' : 'green.500'}
                        fontWeight="900"
                        fontFamily="Bold"
                      >
                        {`${bandwidthUsed > 0 ? bandwidthUsed.toFixed(2) : bandwidthUsed} GB`}
                      </Text>
                      <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                        {`(${bandwidthPercentageUsedFixed}%)`}
                      </Text>
                    </Box>
                    <Progress
                      borderRadius="30px"
                      size="sm"
                      value={bandwidthPercentageUsed || 0}
                      colorScheme={bandwidthPercentageUsed > 100 ? 'red' : 'green'}
                      bg="#58677E"
                    />
                    <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                      {`of ${bandwidthLimit}GB + ${additionalBandwidthUsed}GB Add-on`}
                    </Text>
                  </Box>
                )}

                {/* <Box display="flex" flexDirection="column" justifyContent="center">
                  <Text fontSize={['15px']} fontWeight="900" fontFamily="Bold">
                    1.5 GB
                  </Text>
                  <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                    This Month
                  </Text>
                  <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                    30% used
                  </Text>
                </Box> */}

                {bandwidthUnitPrice > 0 && (
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                    <Text fontSize={['15px']} color="#D3BB8A" fontWeight="900" fontFamily="Bold">
                      {`$${bandwidthUnitPrice} /GB`}
                    </Text>
                    <Text fontSize={['12px']} color="#58677E" fontFamily="Normal">
                      Add-on Rate
                    </Text>
                    <Button variant="link" fontSize={['12px']} color="#D3BB8A" fontFamily="Semi">
                      Buy Now
                    </Button>
                  </Box>
                )}
              </Box>

              <Box>{/* <GraphIcon boxSize={[6]} /> */}</Box>
            </Box>
          </Skeleton>
        </Box>

        <Box
          w={['100%']}
          height={['auto']}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            borderBottom="2px solid #D3BB8A"
            width={['inherit']}
            height={['50px']}
            padding={['16px']}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            mb={5}
          >
            <Text fontFamily="Normal" fontSize={['15px']} color="#D3BB8A" fontWeight="900">
              Settings
            </Text>
          </Box>

          <Box width={['inherit']} height={['auto']} display="flex" flexDirection="column" gap={5} alignItems="center">
            <BillingOption
              title="Modify Plan"
              description="Update your plan options here."
              onClick={() => setShowPlans(true)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default OrganisationInfo;
