import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const MagnifyingGlass = (props: IconProps) => (
  <Icon width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.3095 19.8851L17.4197 15.9953C19.5933 13.355 19.4469 9.43386 16.9805 6.96745C14.3586 4.34418 10.091 4.34418 7.46778 6.96745C6.19732 8.2379 5.49805 9.92662 5.49805 11.7238C5.49805 13.521 6.19732 15.2097 7.46778 16.4802C8.77876 17.7912 10.5015 18.4473 12.2242 18.4473C13.7416 18.4473 15.2591 17.9376 16.4956 16.9194L20.3854 20.8092C20.5135 20.9373 20.6808 21 20.8481 21C21.0154 21 21.1827 20.936 21.3108 20.8092C21.5657 20.5543 21.5657 20.14 21.3108 19.8851H21.3095ZM8.39187 15.5561C7.36844 14.5327 6.8051 13.172 6.8051 11.7238C6.8051 10.2756 7.36844 8.91496 8.39187 7.89153C9.44797 6.83543 10.8361 6.30738 12.2242 6.30738C13.6123 6.30738 15.0003 6.83543 16.0564 7.89153C18.1686 10.005 18.17 13.4426 16.0564 15.5561C13.9429 17.6696 10.5054 17.6696 8.39187 15.5561Z"
      fill="white"
    />
  </Icon>
);
