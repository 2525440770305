import { Box, Button, Collapse, Skeleton, Text, useToast } from '@chakra-ui/react';
import { BillingCard } from '../../interfaces';
import { useEffect, useState } from 'react';
import AddCardForm from '../forms/AddCardForm';
import { useUserStore } from '../../store/user';
import BillingCardItem from '../cards/BillingCardItem';
import configureAxios from '../../axiosClient';
import React from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';

const axiosClient = configureAxios();

function CardManager() {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const [loadingCards, setLoadingCards] = useState(true);
  const [billingCards, setBillingCards] = useState<BillingCard[]>([]);
  const [addCardViewActive, setAddCardViewActive] = useState(false);
  const organisation = useUserStore((state) => state.organisation);

  useEffect(() => {
    setLoadingCards(true);
    const userOrgId = organisation.organizationId;
    axiosClient
      .get(`org/${userOrgId}/card/read`)
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          const cards = res.data.data as BillingCard[];
          setBillingCards(res.data.data);
          if (res.data.data.length === 1) {
            const singleCardId = cards[0].id;
            updateDefaultCard(singleCardId, cards);
          } else {
            setLoadingCards(false);
          }
        } else {
          toast({
            title: `There was an error fetching your cards`,
            status: 'error',
          });
        }
      })
      .catch((err: unknown) => {
        console.log(err);
      });
  }, [organisation, addCardViewActive]);

  const deleteCard = (cardId: string) => {
    const isDefault = billingCards.find(
      (billingCard: BillingCard) => billingCard.id === cardId && billingCard.default_card,
    );

    if (isDefault) {
      if (!toast.isActive('deletingDefaultCard')) {
        toast({
          id: 'deletingDefaultCard',
          title: `Cannot delete a default card`,
          status: 'error',
        });
      }
      return;
    }

    setLoadingCards(true);
    const userOrgId = organisation.organizationId;
    if (!addCardViewActive) {
      axiosClient
        .get(`org/${userOrgId}/card/delete/${cardId}`)
        .then((res) => {
          const isStatusOk = res.data.status === 'OK';
          if (isStatusOk) {
            setBillingCards([...billingCards.filter((billingCard: BillingCard) => billingCard.id !== cardId)]);
            setLoadingCards(false);
          } else {
            toast({
              title: `There was an error deleting card`,
              status: 'error',
            });
          }
        })
        .catch((err: unknown) => {
          console.log(err);
        });
    }
  };

  const updateDefaultCard = (cardId: string, cards?: BillingCard[]) => {
    setLoadingCards(true);
    const userOrgId = organisation.organizationId;
    if (!addCardViewActive) {
      axiosClient
        .get(`org/${userOrgId}/card/default/${cardId}`)
        .then((res) => {
          const isStatusOk = res.data.status === 'OK';
          if (isStatusOk) {
            if (cards?.length === 1) {
              setBillingCards([
                ...cards.map((billingCard: BillingCard) =>
                  billingCard.id === cardId
                    ? { ...billingCard, default_card: true }
                    : { ...billingCard, default_card: false },
                ),
              ]);
            } else {
              setBillingCards([
                ...billingCards.map((billingCard: BillingCard) =>
                  billingCard.id === cardId
                    ? { ...billingCard, default_card: true }
                    : { ...billingCard, default_card: false },
                ),
              ]);
            }

            setLoadingCards(false);
          } else {
            toast({
              title: `There was an error updating default card`,
              status: 'error',
            });
          }
        })
        .catch((err: unknown) => {
          console.log(err);
        });
    }
  };

  return (
    <Box gap={5} width={['100%']} display="flex" flexDirection="column" alignItems="center">
      <Text
        color="#08101D"
        fontFamily="Metal"
        fontSize={['35px']}
        lineHeight={['35px']}
        textAlign="center"
        textTransform="uppercase"
      >
        Billing Details
      </Text>

      <Collapse style={{ width: '100%' }} animateOpacity in={!addCardViewActive}>
        <Box
          width={['100%']}
          height={['auto']}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={4}
          padding={['10px 0px']}
        >
          <Skeleton
            startColor="lightgrey"
            endColor="white"
            isLoaded={!loadingCards}
            width={['100%']}
            borderRadius="16px"
          >
            {billingCards.length > 0 ? (
              <Box width={['100%']}>
                <Text
                  color="#08101D"
                  fontSize={['15px']}
                  fontFamily="Normal"
                  textTransform="capitalize"
                  fontWeight="900"
                  padding={['0px 10px']}
                >
                  Payment Method
                </Text>
                <Box
                  width={['inherit']}
                  height={['auto']}
                  maxH={['400px']}
                  overflow="auto"
                  mt={['10px']}
                  padding={['0px 10px']}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={4}
                >
                  {billingCards.map((billingCard: BillingCard) => (
                    <BillingCardItem
                      key={billingCard.id}
                      isDefault={billingCard.default_card}
                      name={billingCard?.billing_details?.name}
                      last4={billingCard.last4}
                      expMonth={billingCard.exp_month}
                      expYear={billingCard.exp_year}
                      updateDefaultCard={() => updateDefaultCard(billingCard.id)}
                      deleteCard={() => deleteCard(billingCard.id)}
                    />
                  ))}
                </Box>
              </Box>
            ) : (
              <Box width={['100%']} display="flex" flexDirection="column" alignItems="center">
                <Text fontFamily="Normal" color="#08101D" fontSize={['14px']} fontWeight="900">
                  Payment Method
                </Text>
                <Text fontFamily="Normal" color="#08101D" fontSize={['12px']}>
                  Your account is not linked to any payment methods
                </Text>
              </Box>
            )}
          </Skeleton>
          <Button
            type="submit"
            textTransform="uppercase"
            width={['80%', '80%', '300px']}
            variant="outline"
            color="#08101D"
            background="white"
            fontFamily="Metal"
            fontSize={['14px', '14px', '15px']}
            borderRadius="30px"
            border="1px solid #D6DBE4"
            _hover={{
              bg: '#D3BB8A',
              borderColor: '#D3BB8A',
            }}
            onClick={() => setAddCardViewActive(true)}
          >
            Add Card
          </Button>
        </Box>
      </Collapse>

      <Collapse style={{ width: '100%' }} animateOpacity in={addCardViewActive}>
        {addCardViewActive && (
          <Box
            width={['100%']}
            gap={5}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box width={['100%']} padding={['10px 0px']} maxHeight={['500px']} overflow="auto">
              <AddCardForm onCardAdded={() => setAddCardViewActive(false)} />
            </Box>
            <Button
              leftIcon={<ChevronLeftIcon boxSize={[4]} color="currentColor" />}
              fontSize="12px"
              variant="link"
              fontFamily="Normal"
              onClick={() => setAddCardViewActive(false)}
            >
              Go Back
            </Button>
          </Box>
        )}
      </Collapse>
    </Box>
  );
}

export default CardManager;
