import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const TextIcon = (props: IconProps) => (
  <Icon width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.99609 10.9999V1.30273" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.18262 2.63407V1H10.8167V2.63407" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.60547 11H8.39412" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
