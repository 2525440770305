import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Upload = (props: IconProps) => (
  <Icon width="5" height="5" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.61914 11.3242H7.25845" stroke="#08101D" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.67318 3.99805C7.79746 4.0995 7.07125 4.70824 6.79892 5.51989C6.69212 5.84561 6.36105 6.03251 6.01931 5.97911C5.82174 5.94707 5.61882 5.94173 5.40523 5.95775C4.15038 6.0592 3.14116 7.35677 3.06641 8.61696"
      stroke="#08101D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2418 11.3235C13.502 11.3235 14.5165 10.2982 14.5005 9.03269C14.4845 7.76716 13.5073 6.57104 12.3059 6.53901C12.1884 6.53901 12.0709 6.53901 11.9534 6.55502C11.5743 6.60308 11.2219 6.32541 11.1738 5.94629"
      stroke="#08101D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.76562 9.75967L9.61319 7.91211L11.4608 9.75967"
      stroke="#08101D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.61328 12.8247V7.91211" stroke="#08101D" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M2.5 11.6222L12.0852 3.17578"
      stroke="#08101D"
      strokeWidth="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
