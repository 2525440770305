import { Fragment, ReactNode } from 'react';
import { useUserStore } from './store/user';
import { Navigate } from 'react-router-dom';
import React from 'react';

export function AuthorizedRoute({ children }: { children: ReactNode }) {
  const { roles: userRoles } = useUserStore();

  const canAccess = userRoles.includes('owner') || userRoles.includes('admin');

  if (canAccess) return <Fragment>{children}</Fragment>;

  return <Navigate to="/" />;
}
