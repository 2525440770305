import { TableContainer, Table, Text, Thead, Tr, Th, Tbody, Td, Box, Image, Fade, Button } from '@chakra-ui/react';
import Unreal from '../assets/image/Unreal.png';
import { Plugin } from '../interfaces';
import { Plugins } from '../Icons/Plugins';
import TopActionBar from '../components/common/TopActionBar';
import PluginsBarActions from '../components/barActions/PluginsBarActions';
import { DownloadIcon } from '@chakra-ui/icons';
import React from 'react';
import { dispatchPluginEventToAnalytics } from '../analytics';
import { useUserStore } from '../store/user';

function PluginsView() {
  const { organisation, user } = useUserStore();

  const plugins: Plugin[] = [
    {
      image: Unreal,
      title: 'Creator Tools for Unreal Engine',
      description:
        'Go from a game engine 3D model to a fully functional, high quality web or spatial computing virtual world in minutes.',
      supportedPlatforms: 'Windows',
      supportedEngines: 'UE 5.3',
      version: '1.0.0',
      date: '12/05/2024',
      btnText: 'Download',
      link: process.env.REACT_APP_PLUGIN_URL || '',
    },
  ];

  return (
    <Fade in={true} transition={{ enter: { duration: 0.2 } }}>
      <Box>
        <TopActionBar
          icon={<Plugins stroke="white" boxSize={[5]} />}
          title="Plugins"
          showSearchBar={false}
          rightActions={<PluginsBarActions />}
        />

        <TableContainer p={['0px 40px']}>
          <Table variant="simple" size="lg">
            <Thead height={['auto']}>
              <Tr>
                <Th borderBottom="2px solid #D3BB8A" padding={['16px']}>
                  <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']} color="#D3BB8A">
                    Plugins
                  </Text>
                </Th>
                <Th borderBottom="2px solid #D3BB8A" padding={['16px']}>
                  <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']} lineHeight={['25px']}>
                    Supported
                  </Text>
                  <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']} lineHeight={['25px']}>
                    Platforms
                  </Text>
                </Th>
                <Th borderBottom="2px solid #D3BB8A" padding={['16px']}>
                  <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']} lineHeight={['25px']}>
                    Supported
                  </Text>
                  <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']} lineHeight={['25px']}>
                    engines
                  </Text>
                </Th>
                <Th borderBottom="2px solid #D3BB8A" padding={['16px']}>
                  <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']}>
                    Version
                  </Text>
                </Th>
                <Th borderBottom="2px solid #D3BB8A" padding={['16px']}>
                  <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']}>
                    date
                  </Text>
                </Th>
                <Th borderBottom="2px solid #D3BB8A" padding={['16px']}>
                  <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']}>
                    Status
                  </Text>
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {plugins.map((plugin: Plugin) => (
                <Tr key={plugin.title} padding={['16px']}>
                  <Td padding={['16px']}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      width={['300px', '300px', '300px', '400px', '600px']}
                    >
                      <Image src={plugin.image} height={['inherit']} width={['70px']} />
                      <Box height={['100%']} ml={['10px']} width={['100%']}>
                        <Text fontSize={['14px']} fontFamily="Normal" noOfLines={2}>
                          {plugin.title}
                        </Text>
                        <Box
                          fontSize={['12px']}
                          lineHeight={'15px'}
                          fontFamily="Normal"
                          noOfLines={3}
                          whiteSpace="break-spaces"
                        >
                          {plugin.description}
                        </Box>
                      </Box>
                    </Box>
                  </Td>
                  <Td padding={['16px']}>
                    <Text fontFamily="Normal" fontSize={['14px']}>
                      {plugin.supportedPlatforms}
                    </Text>
                  </Td>
                  <Td padding={['16px']}>
                    <Text fontFamily="Normal" fontSize={['14px']}>
                      {plugin.supportedEngines}
                    </Text>
                  </Td>
                  <Td padding={['16px']}>
                    <Text fontFamily="Normal" fontSize={['14px']}>
                      {plugin.version}
                    </Text>
                  </Td>
                  <Td padding={['16px']}>
                    <Text fontFamily="Normal" fontSize={['14px']}>
                      {plugin.date}
                    </Text>
                  </Td>
                  <Td padding={['16px']}>
                    <Button
                      rightIcon={<DownloadIcon boxSize={[4]} color="currentColor" />}
                      variant="link"
                      fontFamily="Metal"
                      fontSize={['14px']}
                      color="white"
                      onClick={() => {
                        window.open(plugin.link, '_self');
                        dispatchPluginEventToAnalytics('download_plugin', {
                          plugin_version: plugin.version,
                          engine_version: plugin.supportedEngines,
                          engine_name: plugin.title,
                          organisation_id: organisation.organizationId,
                          organisation_user_id: user.id,
                        });
                      }}
                    >
                      {plugin.btnText}
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Fade>
  );
}

export default PluginsView;
