import { Box, Button, Collapse, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import UpdateSourceMaterialForm from '../forms/UpdateSourceMaterialForm';
import { useNavigate } from 'react-router-dom';

interface UpdateSourceMaterialsModalProps {
  updateSourceModalActive: boolean;
  setUpdateSourceModalActive: (state: boolean) => void;
  setChangeInExperience: (state: boolean) => void;
}

function UpdateSourceMaterialsModal({
  updateSourceModalActive,
  setUpdateSourceModalActive,
  setChangeInExperience,
}: UpdateSourceMaterialsModalProps) {
  const navigate = useNavigate();
  const [experienceName, setExperienceName] = useState('');
  const [updatingExperience, setUpdatingExperience] = useState<boolean>(false);

  return (
    <Modal
      isOpen={updateSourceModalActive}
      onClose={() => {
        setUpdateSourceModalActive(false);
        setUpdatingExperience(false);
      }}
      isCentered={updatingExperience}
    >
      <ModalOverlay />
      <ModalContent
        minW={['500px']}
        height={['auto']}
        padding={['20px']}
        borderRadius="16px"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <CloseIcon
          position="absolute"
          boxSize={[4]}
          cursor="pointer"
          color="#D6DBE4"
          _hover={{ color: 'black' }}
          transition="0.2s all"
          right={['20px']}
          top={['20px']}
          onClick={() => setUpdateSourceModalActive(false)}
        />
        <ModalBody display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
          <Collapse
            in={!updatingExperience}
            animateOpacity
            style={{
              overflow: updatingExperience ? 'hidden' : 'unset',
            }}
          >
            <Text
              color="#08101D"
              fontFamily="Metal"
              fontSize={['35px']}
              lineHeight={['35px']}
              textAlign="center"
              textTransform="uppercase"
            >
              Update Source
            </Text>

            <UpdateSourceMaterialForm
              updateExperience={(expName) => {
                setExperienceName(expName);
              }}
              setChangeInExperience={setChangeInExperience}
              setUpdatingExperience={setUpdatingExperience}
            />
          </Collapse>

          <Collapse in={updatingExperience} animateOpacity>
            <Box
              m={['20px 0px']}
              width={['100%']}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" gap={1}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Text fontFamily="Normal" color="#08101D" fontSize={['14px']}>
                    Updating Experience:
                  </Text>
                  <Text fontFamily="Normal" color="#08101D" fontSize={['14px']} fontWeight="900">
                    {experienceName}
                  </Text>
                </Box>
                <Spinner color="#08101D" />
              </Box>

              <Box textAlign="center">
                <Text fontFamily="Normal" color="#08101D" fontSize={['12px']}>
                  Your experience is being processed.
                </Text>
                <Text fontFamily="Normal" color="#08101D" fontSize={['12px']}>
                  You can close this window now or view the status in processes tab.
                </Text>
              </Box>

              <Button
                textTransform="uppercase"
                width={['200px']}
                variant="outline"
                color="#08101D"
                background="white"
                fontFamily="Metal"
                fontSize={['14px', '14px', '14px']}
                borderRadius="30px"
                border="1px solid #D6DBE4"
                _hover={{
                  bg: '#D3BB8A',
                  borderColor: '#D3BB8A',
                }}
                onClick={() => {
                  navigate('/processes');
                }}
              >
                View Processes
              </Button>
            </Box>
          </Collapse>

          <Button
            fontSize={['11px']}
            variant="link"
            fontFamily="Normal"
            textAlign="center"
            onClick={() => {
              setUpdateSourceModalActive(false);
              setUpdatingExperience(false);
            }}
          >
            {updatingExperience ? 'Close' : 'Cancel'}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default UpdateSourceMaterialsModal;
