import { Box, Button, Switch, Text, useToast } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { SidebarOption } from '../../interfaces';
import { Experiences } from '../../Icons/Experiences';
import { Plugins } from '../../Icons/Plugins';
import { Processes } from '../../Icons/Processes';
import Plans from './Plans';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { useUserStore } from '../../store/user';
import UserInvitesModal from '../modals/UserInvitesModal';
import CancelContactModal from '../modals/CancelContactModal';
import { usePlanStore } from '../../store/plan';
import { useAuthStore } from '../../store/auth';
import React from 'react';
import { Copy } from '../../Icons/Copy';
import { useExperiencesStore } from '../../store/experiences';
import { LeftArrow } from '../../Icons/LeftArrow';
import EnterpriseContactModal from '../modals/EnterpriseContactModal';
import configureAxios from '../../axiosClient';
import EmbedIframeModal from '../modals/EmbedIframeModal';
import ContactModal from '../modals/ContactModal';
import { dispatchExperienceToAnalytics } from '../../analytics';
import GettingStartedModal from '../modals/GettingStartedModal';
import NearingUsageLimitModal from '../modals/NearingUsageLimitModal';
import LimitReachedModal from '../modals/LimitReachedModal';

interface OverlayProps {
  children: React.ReactNode;
}

const axiosClient = configureAxios();

function Overlay({ children }: OverlayProps) {
  const location = useLocation();
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const navigate = useNavigate();
  const [userInvitesModalActive, setUserInvitesModalActive] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [viewerActive, setViewerActive] = useState(false);

  const [activeView, setActiveView] = useState('experiences');
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const { organisation, getUser, getOrganizations, user } = useUserStore();
  const {
    showGettingStartedModal,
    setShowPlans,
    showCancelPlan,
    showContactModal,
    setShowContactModal,
    setShowCancelPlans,
    showEnterpriseForm,
    setShowEnterpriseForm,
    setShowGettingStartedModal,
  } = usePlanStore();
  const { isLoggedIn } = useAuthStore();

  const {
    selectedExperience,
    setSelectedExperience,
    setViewerUnPublishExperienceModalActive,
    showEmbedModal,
    setShowEmbedModal,
    showNearingUsageLimitActive,
    showLimitReachedModalActive,
    setNearingUsageLimitActive,
    setLimitReachedModalActive,
  } = useExperiencesStore();
  const [debugActive, setDebugActive] = useState(false);

  useEffect(() => {
    isLoggedIn && getUser();
    isLoggedIn && getOrganizations();
  }, [isLoggedIn]);

  useEffect(() => {
    const userOrgId = organisation.organizationId;
    const getUsageStatus = async () => {
      if (userOrgId?.length > 0) {
        try {
          const res = await axiosClient.get(`org/${userOrgId}/usage/status`);
          if (res.data.status === 'OK') {
            res.data = res.data.data;
            if (res.data.is_organisation_blocked_due_to_bandwidth || res.data.is_organisation_blocked_due_to_storage) {
              if (res.data.is_bandwidth_usage_high || res.data.is_storage_usage_high) {
                setLimitReachedModalActive(true);
              }
            } else {
              if (res.data.is_bandwidth_usage_high || res.data.is_storage_usage_high) {
                setNearingUsageLimitActive(true);
              }
            }
          }
        } catch (err) {
          console.log('Error', err);
        }
      }
    };
    getUsageStatus();
  }, [organisation.organizationId]);

  useEffect(() => {
    if (location.pathname.includes('viewer')) {
      setViewerActive(true);
    } else {
      setViewerActive(false);
      const activeSidebarOption = sidebarOptions.find(
        (sidebarOption: SidebarOption) => sidebarOption.href === location.pathname,
      );
      activeSidebarOption ? setActiveView(activeSidebarOption.key) : setActiveView('');
    }
  }, [location.pathname]);

  const sidebarOptions: SidebarOption[] = [
    {
      href: '/',
      key: 'experiences',
      text: 'Experiences',
      sideBarIcon: <Experiences stroke="currentColor" boxSize={[5]} />,
    },
    {
      href: '/plugins',
      key: 'plugins',
      text: 'Plugins',
      sideBarIcon: <Plugins stroke="currentColor" boxSize={[5]} />,
    },
    {
      href: '/processes',
      key: 'processes',
      text: 'Processes',
      sideBarIcon: <Processes stroke="currentColor" boxSize={[5]} />,
    },
  ];

  const activateDebugMode = (event: ChangeEvent<HTMLInputElement>) => {
    setDebugActive(event.target.checked);

    if (event.target.checked) {
      setSelectedExperience({
        ...selectedExperience,
        preview_url: selectedExperience?.preview_url + '?debug=true',
      });
    } else {
      setSelectedExperience({
        ...selectedExperience,
        preview_url: selectedExperience?.preview_url.split('?')[0],
      });
    }
  };

  const handleCopy = async (text: string) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      }
      if (!toast.isActive('viewerLink')) {
        toast({
          id: 'viewerLink',
          title: `Link Copied`,
          status: 'success',
        });
      }
    } catch (err) {
      console.error('Async: Could not copy text', err);
    }
  };

  const handlePublish = () => {
    if (selectedExperience.published_url?.length > 0) {
      setViewerUnPublishExperienceModalActive(true);
    } else {
      publishExperience();
    }
  };

  const publishExperience = () => {
    const userOrgId = organisation.organizationId;

    setIsSubmitting(true);
    axiosClient
      .post(`/experience/publish/${userOrgId}/${selectedExperience.id}`)
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          dispatchExperienceToAnalytics('publish_experience', {
            experience_id: selectedExperience.id,
            organisation_id: userOrgId,
            organisation_user_id: user.id,
            experience_name: selectedExperience.name,
            experience_draft_url: selectedExperience.preview_url,
            experience_published_url: res.data?.data?.published_url,
          });
          const error = res?.data?.data?.error;
          if (error && error.length > 0) {
            toast({
              title: typeof error === 'string' ? error : `A problem occurred publishing the experience.`,
              status: 'error',
            });
          } else {
            setSelectedExperience({
              ...selectedExperience,
              published_url: res.data?.data?.published_url,
            });
            toast({
              title: `Your experience was published successfully.`,
              status: 'success',
            });
          }
        } else {
          if (res.data.data === 'Insufficient Permissions.') {
            toast({
              title: `Insufficient Permissions`,
              status: 'info',
            });
          } else {
            toast({
              title: `A problem occurred publishing the experience.`,
              status: 'error',
            });
          }
        }
        setIsSubmitting(false);
      })
      .catch((err: unknown) => {
        console.log(err);
        setIsSubmitting(false);
        toast({
          title: `A problem occurred publishing the experience.`,
          status: 'error',
        });
      });
  };

  return (
    <Fragment>
      {viewerActive ? (
        <Box w="100%" h="100vh" display="flex" flexDirection="column">
          <Box
            cursor="pointer"
            position="absolute"
            height={['65px']}
            borderBottom="1px solid rgba(242, 243, 245, 0.14)"
            background="rgba(8, 16, 29, 0.60)"
            backdropFilter="blur(20px)"
            color="#fff"
            textAlign="center"
            zIndex={10}
            width={['100%']}
            padding={['0px 20px']}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              height={['inherit']}
              width={['auto']}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={3}
            >
              <LeftArrow boxSize={[5]} stroke="white" cursor="pointer" onClick={() => navigate('/')} />
              <Box
                height={['inherit']}
                width={['auto']}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={6}
              >
                <Text fontFamily="Normal" fontSize={['15px']}>
                  {selectedExperience.name + ' ' + selectedExperience.description}
                </Text>
                <Text fontFamily="Normal" fontSize={['13px']} color="#4DFF7B" maxW={['250px']} noOfLines={1}>
                  {selectedExperience.preview_url}
                </Text>
              </Box>

              <Button
                variant="link"
                leftIcon={<Copy stroke="currentColor" boxSize={[4]} />}
                fontFamily="Normal"
                fontSize={['12px']}
                color="#FFFFFF"
                onClick={() => handleCopy(selectedExperience.preview_url)}
              >
                Copy Link
              </Button>
            </Box>

            <Box height={['inherit']} width={['auto']} display="flex" alignItems="center" gap={3}>
              <Button
                rightIcon={
                  <Switch
                    id="toggle-debug"
                    size="sm"
                    colorScheme="blue"
                    isChecked={debugActive}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      activateDebugMode(event);
                      dispatchExperienceToAnalytics('view_debug_mode', {
                        experience_id: selectedExperience.id,
                        organisation_id: organisation.organizationId,
                        organisation_user_id: user.id,
                        experience_name: selectedExperience.name,
                        experience_draft_url: selectedExperience.preview_url,
                        experience_published_url: selectedExperience.published_url,
                      });
                    }}
                  />
                }
                variant="ghost"
                background="transparent"
                _hover={{
                  bg: 'transparent',
                }}
                _active={{
                  bg: 'transparent',
                }}
                _focus={{
                  bg: 'transparent',
                }}
                color="white"
                fontFamily="Normal"
                fontSize={['12px']}
              >
                Debug
              </Button>

              {selectedExperience.published_url?.length > 0 && (
                <Button
                  textTransform="uppercase"
                  width={['auto', 'auto', 'auto']}
                  variant="outline"
                  color="#08101D"
                  background="white"
                  border="1px solid rgba(242, 243, 245, 0.14)"
                  fontFamily="Metal"
                  fontSize={['14px', '14px', '15px']}
                  borderRadius="30px"
                  _hover={{
                    bg: '#D3BB8A',
                  }}
                  onClick={() => window.open(selectedExperience.published_url, '_blank')}
                >
                  View Published Version
                </Button>
              )}

              <Button
                textTransform="uppercase"
                width={['auto', 'auto', 'auto']}
                variant="outline"
                color="#08101D"
                background="white"
                border="1px solid rgba(242, 243, 245, 0.14)"
                fontFamily="Metal"
                fontSize={['14px', '14px', '15px']}
                borderRadius="30px"
                _hover={{
                  bg: '#D3BB8A',
                }}
                isLoading={isSubmitting}
                onClick={handlePublish}
              >
                {selectedExperience.published_url?.length > 0 ? 'UnPublish' : 'Publish'}
              </Button>
            </Box>
          </Box>

          <Box flex="1" display="flex" overflow="hidden">
            <Box flex="1" w="100%" h="100%">
              {children}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box w="100%" h="calc(100vh - 15px)" display="flex" flexDirection="column">
          <Plans />

          <GettingStartedModal
            gettingStartedActive={showGettingStartedModal}
            setGettingStartedActive={(state) => setShowGettingStartedModal(state)}
          />

          <UserInvitesModal
            userInvitesModalActive={userInvitesModalActive}
            setUserInvitesModalActive={(state) => setUserInvitesModalActive(state)}
          />

          <CancelContactModal
            cancelPlanModalActive={showCancelPlan}
            setCancelPlanModalActive={(state) => setShowCancelPlans(state)}
          />

          <ContactModal
            contactModalActive={showContactModal}
            setContactModalActive={(state) => setShowContactModal(state)}
          />

          <EnterpriseContactModal
            enterpriseContactActive={showEnterpriseForm}
            setEnterpriseContactActive={(state) => setShowEnterpriseForm(state)}
          />

          <EmbedIframeModal
            embedIframeModalActive={showEmbedModal}
            setEmbedIframeModalActive={(state) => setShowEmbedModal(state)}
          />

          <Navbar
            sideBarOpen={sideBarOpen}
            setShowPlans={(state) => setShowPlans(state)}
            setUserInvitesModalActive={(state) => setUserInvitesModalActive(state)}
          />

          <Sidebar
            sideBarOpen={sideBarOpen}
            activeView={activeView}
            sidebarOptions={sidebarOptions}
            setActiveView={(view) => setActiveView(view)}
            setSideBarOpen={(state) => setSideBarOpen(state)}
          />

          <NearingUsageLimitModal
            nearingUsageLimitActive={showNearingUsageLimitActive}
            setContactModalActive={setShowContactModal}
            setNearingUsageLimitActive={setNearingUsageLimitActive}
          />
          <LimitReachedModal
            limitReachedModalActive={showLimitReachedModalActive}
            setContactModalActive={setShowContactModal}
            setLimitReachedModalActive={setLimitReachedModalActive}
          />

          <Box
            display="flex"
            flex="1"
            mt={['65px']}
            transition="0.2s all"
            ml={sideBarOpen ? '220px' : '60px'}
            height={['calc(100vh - 65px)']}
            zIndex="50"
          >
            <Box
              flex="1"
              width={['100%']}
              display="flex"
              flexDirection="column"
              minWidth={['1000px', '1000px', '1000px', 'unset']}
            >
              {children}
            </Box>
          </Box>
        </Box>
      )}
    </Fragment>
  );
}

export default Overlay;
