import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const UploadCircle = (props: IconProps) => (
  <Icon width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M4.37793 5.46599L5.99969 3.84424L7.62643 5.46599" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 8.16061V3.84424" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M1.56836 3.67961C1.99251 2.87622 2.62624 2.20257 3.39471 1.72852"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.23453 7.51696C1.08483 7.03792 1 6.53392 1 6.00498C1 5.63572 1.03992 5.27644 1.11477 4.93213"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.60467 10.3914C2.86115 9.98219 2.22742 9.39337 1.77832 8.6748"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.64746 1.18463C5.0766 1.06487 5.53069 1 5.99976 1C8.76423 1 10.9998 3.24052 10.9998 6C10.9998 8.75948 8.75924 11 5.99976 11C5.57061 11 5.15644 10.9451 4.76223 10.8453"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.37793 5.46599L5.99969 3.84424L7.62643 5.46599" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 8.16061V3.84424" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M1.56836 3.67961C1.99251 2.87622 2.62624 2.20257 3.39471 1.72852"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.23453 7.51696C1.08483 7.03792 1 6.53392 1 6.00498C1 5.63572 1.03992 5.27644 1.11477 4.93213"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.60467 10.3914C2.86115 9.98219 2.22742 9.39337 1.77832 8.6748"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.64746 1.18463C5.0766 1.06487 5.53069 1 5.99976 1C8.76423 1 10.9998 3.24052 10.9998 6C10.9998 8.75948 8.75924 11 5.99976 11C5.57061 11 5.15644 10.9451 4.76223 10.8453"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
