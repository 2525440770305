import { Box, Text } from '@chakra-ui/react';
import UserInviteForm from '../forms/UserInviteForm';
import React from 'react';

function UserInvite() {
  return (
    <Box
      w={['100%', '380px', '480px']}
      height={['auto']}
      p="30px"
      borderRadius="16px"
      background="#1D2531"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      gap={5}
    >
      <Text
        fontFamily="Metal"
        fontSize={['30px', '30px', '40px']}
        lineHeight={['30px', '30px', '40px']}
        textAlign="center"
        textTransform="uppercase"
      >
        Invite Your Team
      </Text>

      <UserInviteForm flow="login" showSkip={true} btnText={'Next'} close={() => console.log('Do Nothing')} />
    </Box>
  );
}

export default UserInvite;
