import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Copy = (props: IconProps) => (
  <Icon width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.72144 2.82248L9.86017 1.68375C10.5868 0.95713 11.2212 1.1903 11.7581 1.72713L12.5009 2.47002C13.0378 3.00685 13.2655 3.64671 12.5443 4.36791L8.55334 8.3589C7.82672 9.08552 7.19228 8.85235 6.65545 8.31552L5.91256 7.57263C5.18052 6.84058 5.55468 6.21699 6.36806 5.40903"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.41391 9.04187L4.13961 10.3162C3.41299 11.0428 2.77855 10.8096 2.24172 10.2728L1.49883 9.5299C0.962002 8.99307 0.734255 8.35321 1.45545 7.63201L5.44644 3.64103C6.17306 2.91441 6.8075 3.14757 7.34433 3.68441L8.08722 4.42729C8.62405 4.96412 8.91687 5.53891 7.7456 6.71018"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
