import { Box, Text } from '@chakra-ui/react';
import { useUserStore } from '../../store/user';
import React from 'react';

interface OrganisationCardProps {
  membersCount: string;
}

function OrganisationCard({ membersCount }: OrganisationCardProps) {
  const { organisation } = useUserStore();

  return (
    <Box
      borderRadius="30px"
      border="1px solid rgba(242, 243, 245, 0.14)"
      p={['30px']}
      width={['100%']}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Text fontFamily="Metal" textTransform="capitalize" fontSize={['20px']}>
        {organisation.alias}
      </Text>
      <Box display="flex">
        <Text fontFamily="Metal" textTransform="capitalize" fontSize={['15px']} color="#D3BB8A">
          MEMBERS
        </Text>
        <Text fontFamily="Metal" textTransform="capitalize" fontSize={['15px']} ml={['10px']}>
          {membersCount}
        </Text>
      </Box>
    </Box>
  );
}

export default OrganisationCard;
