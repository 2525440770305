import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import InfoPopover from '../popover/InfoPopover';
import UpdateSceneGuide from '../updateSceneGuide/UpdateSceneGuide';
import UpdateSceneGuideContent from '../updateSceneGuide/UpdateSceneGuideContent';

interface UpdateExperienceViewProps {
  sceneRef: React.RefObject<HTMLDivElement>;
  title: string;
  subTitle: string;
  description?: string;
  documentationLink: string;
  sceneUpdateType: boolean;
  isHovered: boolean;
  onClick: () => void;
}

const UpdateExperienceView = ({
  sceneRef,
  title,
  subTitle,
  description,
  documentationLink,
  sceneUpdateType,
  isHovered,
  onClick,
}: UpdateExperienceViewProps) => {
  return (
    <Box
      ref={sceneRef}
      w={['100%']}
      padding={['30px']}
      pb={sceneUpdateType ? '0px' : '30px'}
      position={'relative'}
      _hover={{
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <InfoPopover trigger={<UpdateSceneGuide />} content={<UpdateSceneGuideContent link={documentationLink} />} />
      <Text
        fontFamily="Normal"
        color={sceneUpdateType || isHovered ? '#08101D' : '#7F8B9E'}
        fontSize={['15px']}
        fontWeight="700"
        textTransform="capitalize"
      >
        {title}
      </Text>
      <Text
        fontFamily="Normal"
        color={sceneUpdateType || isHovered ? '#08101D' : '#7F8B9E'}
        fontSize={['11px']}
        mt={'3px'}
      >
        {subTitle}
      </Text>
      {description && (
        <Text fontFamily="Normal" color="#7F8B9E" fontSize={['11px']} lineHeight={'13px'} mt={'3px'}>
          {description}
        </Text>
      )}
    </Box>
  );
};

export default UpdateExperienceView;
