import { Box, Text } from '@chakra-ui/react';
import CreateAccountForm from '../forms/CreateAccountForm';
import React from 'react';

function CreateAccount() {
  return (
    <Box
      w={['100%', '380px', '480px']}
      height={['auto']}
      p="30px"
      borderRadius="16px"
      background="#1D2531"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      gap={5}
    >
      <Text
        fontFamily="Metal"
        fontSize={['30px', '30px', '40px']}
        lineHeight={['30px', '30px', '40px']}
        textAlign="center"
        textTransform="uppercase"
      >
        Create Account
      </Text>

      <CreateAccountForm />
    </Box>
  );
}

export default CreateAccount;
