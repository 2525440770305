import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Plugins = (props: IconProps) => (
  <Icon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.46463 1.5H1.62305V8.34158H8.46463V1.5Z"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3767 1.5H11.5352V8.34158H18.3767V1.5Z"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.46463 11.656H1.62305V18.4976H8.46463V11.656Z"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14.957 11.656V18.5" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.3767 15.0779H11.5352" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
