import { Popover, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import React from 'react';

interface InfoPopoverProps {
  trigger: React.ReactNode;
  content: React.ReactNode;
}

const InfoPopover = React.forwardRef<HTMLDivElement, InfoPopoverProps>(({ trigger, content }, ref) => {
  return (
    <Popover placement="right">
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent ref={ref} borderRadius={'16px'}>
        <PopoverBody>{content}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
});

InfoPopover.displayName = 'InfoPopover';

export default InfoPopover;
