import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const DollarCircle = (props: IconProps) => (
  <Icon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="16" height="16" rx="8" />
    <path d="M8 3.33333V3" stroke="white" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.82812 9.87666C5.85191 10.0669 5.85983 10.1224 5.9074 10.2373C6.02234 10.495 6.17295 10.7209 6.36717 10.8953C6.56535 11.0737 6.7992 11.2045 7.06872 11.2916C7.33824 11.3788 7.63154 11.4224 7.94862 11.4224C8.65414 11.4224 9.20111 11.256 9.58954 10.927C9.97796 10.5941 10.1722 10.1303 10.1722 9.52787C10.1722 9.21475 10.1207 8.95315 10.0255 8.74705C9.92644 8.54094 9.79564 8.37051 9.63313 8.23575C9.08616 7.77994 7.14799 8.20404 6.46626 7.6214C6.29979 7.47871 6.15314 7.32017 6.04612 7.1101C5.9391 6.904 5.88361 6.63844 5.88361 6.32136C5.88361 6.0994 5.92721 5.8814 6.01441 5.67133C6.10161 5.46126 6.23241 5.27498 6.40284 5.11247C6.57327 4.94997 6.79127 4.81917 7.0489 4.72404C7.30653 4.62892 7.60776 4.57739 7.94466 4.57739C8.28156 4.57739 8.59072 4.62892 8.84439 4.73197C9.10202 4.83502 9.31209 4.97375 9.48252 5.15211C9.65295 5.33047 9.77582 5.53657 9.86302 5.77042C9.91058 5.90122 9.94625 6.02805 9.96607 6.16678"
      stroke="white"
      strokeLinecap="round"
    />
    <path d="M8.00391 13V11.4304" stroke="white" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.00397 10.1343L8 6.33325"
      stroke="white"
      strokeWidth="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
