import { Button, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import CardManager from '../common/CardManager';
import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';

interface BillingDetailsModalProps {
  billingModalActive: boolean;
  setBillingModalActive: (state: boolean) => void;
}

function BillingDetailsModal({ billingModalActive, setBillingModalActive }: BillingDetailsModalProps) {
  return (
    <Modal
      isOpen={billingModalActive}
      onClose={() => {
        setBillingModalActive(false);
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        minW={['700px']}
        height={['auto']}
        padding={['20px']}
        borderRadius="16px"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <CloseIcon
          position="absolute"
          boxSize={[4]}
          cursor="pointer"
          color="#D6DBE4"
          _hover={{ color: 'black' }}
          transition="0.2s all"
          right={['20px']}
          top={['20px']}
          onClick={() => {
            setBillingModalActive(false);
          }}
        />
        <ModalBody height="auto" width="auto" gap={5} display="flex" flexDirection="column" alignItems="center">
          <CardManager />
          <Button
            fontSize={['12px']}
            variant="link"
            fontFamily="Normal"
            textAlign="center"
            onClick={() => setBillingModalActive(false)}
          >
            Cancel
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default BillingDetailsModal;
