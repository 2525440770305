import { Box, Image, Text } from '@chakra-ui/react';
import { useUserStore } from '../../store/user';
import DummyUser from '../../assets/image/DummyUser.png';
import React from 'react';

function UserProfile() {
  const user = useUserStore((state) => state.user);
  const roles = useUserStore((state) => state.roles);

  return (
    <Box display="flex" alignItems="center" width={['auto']} height={['inherit']} gap={2}>
      <Image src={DummyUser} height={['40px']} width={['40px']} borderRadius="100%" />

      <Box width={['auto']}>
        <Box display="flex" justifyContent="flex-start" alignItems="center" width={['inherit']} gap={2}>
          <Text fontFamily="Normal" fontSize={['15px']}>
            {user.firstName + ' ' + user.lastName}
          </Text>
          <Text fontFamily="Normal" fontSize={['11px']}>
            {roles?.includes('owner')
              ? 'Owner'
              : roles?.includes('admin')
              ? 'Admin'
              : roles?.includes('member') && ' Member'}
          </Text>
        </Box>
        <Text color="#58677e" fontFamily="Normal" fontSize={['14px']} width={['inherit']}>
          {user.email}
        </Text>
      </Box>
    </Box>
  );
}

export default UserProfile;
