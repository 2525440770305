import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type {} from '@redux-devtools/extension';

interface SuggestedPlan {
  plan: string;
  interval: string;
}

interface CurrentPlan {
  id: string;
  name: string;
  standingCharge: number;
  startDate: string;
}

interface PlanState {
  currentPlan: CurrentPlan;
  suggestedPlan: SuggestedPlan;
  showPlans: boolean;
  showCancelPlan: boolean;
  showEnterpriseForm: boolean;
  showContactModal: boolean;
  showGettingStartedModal: boolean;
  setCurrentPlan: (currentPlan: CurrentPlan) => void;
  setSuggestedPlan: (plan: string, interval: string) => void;
  setShowPlans: (state: boolean) => void;
  setShowCancelPlans: (state: boolean) => void;
  setShowEnterpriseForm: (state: boolean) => void;
  setShowContactModal: (state: boolean) => void;
  setShowGettingStartedModal: (state: boolean) => void;
  resetPlans: () => void;
}

export const usePlanStore = create<PlanState>()(
  devtools(
    persist(
      (set) => ({
        currentPlan: {
          id: '',
          name: '',
          standingCharge: 0,
          startDate: '',
        },
        suggestedPlan: { plan: '', interval: '' },
        showPlans: false,
        showCancelPlan: false,
        showEnterpriseForm: false,
        showContactModal: false,
        showGettingStartedModal: false,
        setCurrentPlan: (currentPlan: CurrentPlan) => set(() => ({ currentPlan: currentPlan })),
        setSuggestedPlan: (plan: string, interval: string) =>
          set(() => ({ suggestedPlan: { plan: plan, interval: interval } })),
        setShowPlans: (state: boolean) => set(() => ({ showPlans: state })),
        setShowCancelPlans: (state: boolean) => set(() => ({ showCancelPlan: state })),
        setShowEnterpriseForm: (state: boolean) => set(() => ({ showEnterpriseForm: state })),
        setShowContactModal: (state: boolean) => set(() => ({ showContactModal: state })),
        setShowGettingStartedModal: (state: boolean) => set(() => ({ showGettingStartedModal: state })),
        resetPlans: () =>
          set(() => ({
            currentPlan: {
              id: '',
              name: '',
              standingCharge: 0,
              startDate: '',
            },
            suggestedPlan: { plan: '', interval: '' },
            showPlans: false,
            showCancelPlan: false,
            showEnterpriseForm: false,
          })),
      }),
      {
        partialize: () => ({ showPlans: false }),
        name: 'plan-storage',
      },
    ),
  ),
);
