import { Box, Button, Divider, Text } from '@chakra-ui/react';

import React from 'react';

type Plan = {
  planType: string;
  charges: string;
  btnText: string;
  targetPeople: string;
  planDuration: string;
};

interface LeftBillingSummaryProps {
  selectedPlan: Plan;
  submittingPlan?: boolean;
  billingPeriod: string;
  btnText: string;
  payForPlan: () => void;
}

function LeftBillingSummary({
  selectedPlan,
  submittingPlan,
  billingPeriod,
  btnText,
  payForPlan,
}: LeftBillingSummaryProps) {
  const isFree = selectedPlan?.planType?.includes('Free');

  return (
    <Box
      height={['550px']}
      width={['320px']}
      borderRadius="30px"
      border="1px solid rgba(242, 243, 245, 0.14)"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        p={['30px']}
        width={['inherit']}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        background="#1D2531"
        borderTopRadius="28px"
        gap={5}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <Text fontFamily="Metal" textTransform="uppercase" fontSize={['35px']} lineHeight={['35px']} color="white">
            {selectedPlan?.planType}
          </Text>
          <Text fontFamily="Normal" textTransform="capitalize" fontSize={['12px']} color="white">
            {selectedPlan?.targetPeople}
          </Text>
        </Box>

        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
          {isFree ? (
            <Text fontFamily="Metal" fontSize={['14px']} textTransform="uppercase">
              No Charges
            </Text>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="flex-end" gap={2}>
              <Text fontFamily="Metal" fontSize={['35px']} lineHeight={['35px']}>
                30
              </Text>
              <Text fontFamily="Metal" fontSize={['14px']} textTransform="uppercase">
                days for free
              </Text>
            </Box>
          )}
          {isFree ? (
            <Text fontFamily="Normal" fontSize={['12px']} color="white">
              You can shift to a paid plan later
            </Text>
          ) : (
            <Text fontFamily="Normal" fontSize={['12px']} color="white">
              {`Then $${selectedPlan?.charges} ${selectedPlan?.planDuration}. Billed ${billingPeriod}`}
            </Text>
          )}
        </Box>
      </Box>

      <Box
        p={['30px']}
        width={['inherit']}
        height="auto"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        gap={5}
      >
        <Box
          marginTop="auto"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          gap={8}
        >
          <Box width={['100%']} display="flex" flexDirection="column" justifyContent="center">
            <Box width={['100%']} display="flex" flexDirection="column" justifyContent="center">
              <Box width={['100%']} display="flex" justifyContent="space-between" alignItems="center">
                <Text fontFamily="Normal" fontSize={['12px']} padding="12px 16px">
                  Subtotal
                </Text>
                <Text fontFamily="Normal" fontSize={['12px']} padding="12px 16px">
                  {`$${isFree ? '0' : selectedPlan?.charges}.00`}
                </Text>
              </Box>
              <Divider />
            </Box>

            <Box width={['100%']} display="flex" flexDirection="column" justifyContent="center">
              <Box width={['100%']} display="flex" justifyContent="space-between" alignItems="center">
                <Text fontFamily="Normal" fontSize={['12px']} padding="12px 16px">
                  Tax
                </Text>
                <Text fontFamily="Normal" fontSize={['12px']} padding="12px 16px">
                  $0.00
                </Text>
              </Box>
              <Divider borderWidth="1px" opacity="1" />
            </Box>

            <Box width={['100%']} display="flex" flexDirection="column" justifyContent="center">
              <Box width={['100%']} display="flex" justifyContent="space-between" alignItems="center">
                <Text fontFamily="Normal" fontSize={['15px']} padding="12px 16px">
                  Total due
                </Text>
                <Text fontFamily="Normal" fontSize={['15px']} padding="12px 16px">
                  {`$${isFree ? '0' : selectedPlan?.charges}.00`}
                </Text>
              </Box>
              <Divider borderWidth="1px" opacity="1" />
            </Box>
          </Box>

          <Button
            borderRadius="30px"
            width={['100%']}
            fontFamily="Metal"
            fontSize={['14px']}
            color="#08101D"
            bg="white"
            textTransform="uppercase"
            _hover={{
              bg: '#D3BB8A',
            }}
            onClick={payForPlan}
            isLoading={submittingPlan}
          >
            {btnText}
          </Button>

          <Text fontFamily="Normal" fontSize={['12px']} color="white">
            {`After your trial ends, you'll be billed at the chosen subscription rate. You can cancel anytime before then.`}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default LeftBillingSummary;
