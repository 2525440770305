import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
  Image,
  // useToast,
  IconButton,
} from '@chakra-ui/react';
// import configureAxios from '../../axiosClient';
import DummyUser from '../../assets/image/DummyUser.png';
import { Plus } from '../../Icons/Plus';
import React from 'react';

// const axiosClient = configureAxios();

export type MemberItem = {
  id: string;
  name: string;
  surname: string;
  email: string;
  roles: string;
};

interface MembersTableProps {
  members: MemberItem[];
  // setMembers: (members: MemberItem[]) => void;
  openUserInvitesModal: () => void;
}

function MembersTable({ members, openUserInvitesModal }: MembersTableProps) {
  // const toast = useToast({
  //   position: 'top',
  //   variant: 'top-accent',
  // });

  // const rolesPopOver = (role: string) => {
  //   return (
  //     <Popover>
  //       <PopoverTrigger>
  //         <Button
  //           color="white"
  //           rightIcon={<ChevronDownIcon color="currentColor" />}
  //           variant="link"
  //           fontFamily="Normal"
  //           fontSize={['14px']}
  //           textTransform="capitalize"
  //           fontWeight="100"
  //         >
  //           {role}
  //         </Button>
  //       </PopoverTrigger>
  //       <PopoverContent borderRadius="16px" width={['auto']} padding={['16px']} gap={2}>
  //         <PopoverBody padding="0px">
  //           <Box width={'100%'} display="flex" alignItems="center" gap={1}>
  //             <Text color="rgba(127, 139, 158, 1)" textTransform="capitalize" fontFamily="Normal" fontSize={['12px']}>
  //               Owner
  //             </Text>
  //             <LockIcon color="rgba(127, 139, 158, 1)" boxSize={[3]} />
  //           </Box>
  //           <Box width={'100%'} display="flex" alignItems="center" gap={1}>
  //             <Text textTransform="capitalize" fontFamily="Normal" fontSize={['12px']} color="rgba(8, 16, 29, 1)">
  //               Admin
  //             </Text>
  //             {role === 'Admin' && <Tick stroke="rgba(8, 16, 29, 1)" boxSize={[3]} />}
  //           </Box>
  //           <Box width={'100%'} display="flex" alignItems="center" gap={1}>
  //             <Text textTransform="capitalize" fontFamily="Normal" fontSize={['12px']} color="rgba(8, 16, 29, 1)">
  //               Member
  //             </Text>
  //             {role === 'Member' && <Tick stroke="rgba(8, 16, 29, 1)" boxSize={[3]} />}
  //           </Box>
  //         </PopoverBody>
  //         <Divider />
  //         <Button
  //           color="rgba(127, 139, 158, 1)"
  //           leftIcon={<Bin stroke="currentColor" boxSize={[3]} />}
  //           variant="link"
  //           fontFamily="Normal"
  //           fontSize={['12px']}
  //           textTransform="capitalize"
  //           fontWeight="100"
  //           // onClick={() => deleteUser(userId)}
  //         >
  //           Remove
  //         </Button>
  //       </PopoverContent>
  //     </Popover>
  //   );
  // };

  // const deleteUser = (userId: string) => {
  //   axiosClient
  //     .post(`org/${userOrgId}/remove_user`, {
  //       userId: userId,
  //     })
  //     .then((res) => {
  //       const isStatusOk = res.data.status === 'OK';

  //       if (isStatusOk) {
  //         const updatedMembers = members.filter((member: MemberItem) => member.id !== userId);
  //         setMembers(updatedMembers);

  //         toast({
  //           title: `Successfully Deleted`,
  //           status: 'success',
  //         });
  //       } else {
  //         toast({
  //           title: `There was an error deleting the user`,
  //           status: 'error',
  //         });
  //       }
  //     })
  //     .catch((err: unknown) => {
  //       console.log(err);
  //     });
  // };

  return (
    <TableContainer>
      <Table variant="simple" size="lg">
        <Thead height={['auto']}>
          <Tr>
            <Th borderBottom="2px solid #D3BB8A" padding={['16px']}>
              <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']} color="#D3BB8A">
                Name
              </Text>
            </Th>
            <Th borderBottom="2px solid #D3BB8A" padding={['16px']}>
              <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']}>
                email
              </Text>
            </Th>
            <Th borderBottom="2px solid #D3BB8A" padding={['16px']}>
              <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']}>
                team role
              </Text>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {members.map((member: MemberItem, index: number) => (
            <Tr key={index} padding={['16px']} height={['60px']}>
              <Td padding={['16px']}>
                <Box display="flex" gap={1} alignItems="center">
                  <Image src={DummyUser} height={['35px']} width={['35px']} borderRadius="100%" mr={['10px']} />
                  <Text fontSize={['14px']} fontFamily="Semi">
                    {member.name}
                  </Text>
                </Box>
              </Td>
              <Td padding={['16px']}>
                <Text fontFamily="Normal" fontSize={['14px']}>
                  {member.email}
                </Text>
              </Td>
              <Td padding={['16px']}>
                <Text fontFamily="Normal" fontSize={['14px']}>
                  {member?.roles.includes('owner')
                    ? 'Owner'
                    : member?.roles.includes('admin')
                    ? 'Admin'
                    : member?.roles.includes('member') && 'Member'}
                </Text>
              </Td>
            </Tr>
          ))}
          {members.length === 0 && (
            <Tr padding={['16px']} height={['60px']}>
              <Td padding={['16px']}>
                <Box display="flex" gap={2} alignItems="center">
                  <IconButton
                    bg="white"
                    _hover={{
                      bg: '#D3BB8A',
                    }}
                    borderRadius="100%"
                    icon={<Plus boxSize={[5]} stroke="#1D2531" />}
                    aria-label="View Experience"
                    onClick={openUserInvitesModal}
                  />
                  <Text fontSize={['14px']} fontFamily="Semi">
                    Invite Members
                  </Text>
                </Box>
              </Td>
              <Td padding={['16px']}>
                <Text fontFamily="Normal" fontSize={['14px']}></Text>
              </Td>
              <Td padding={['16px']}>
                <Text fontFamily="Normal" fontSize={['14px']}></Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default MembersTable;
