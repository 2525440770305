import { Box, Button, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';

type Invoice = {
  name: string;
  due_date: number;
  paid: boolean;
  total: number;
  url: string;
};

interface BillingHistoryTableProps {
  invoices: Invoice[];
}

function BillingHistoryTable({ invoices }: BillingHistoryTableProps) {
  return (
    <TableContainer mt={['40px']}>
      <Table variant="simple" size="lg">
        <Thead height={['auto']}>
          <Tr>
            <Th width={['400px']} borderBottom="2px solid #D3BB8A" padding={['16px']}>
              <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']} color="#D3BB8A">
                Billing History
              </Text>
            </Th>
            <Th borderBottom="2px solid #D3BB8A" padding={['16px']}>
              <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']}>
                date
              </Text>
            </Th>
            <Th borderBottom="2px solid #D3BB8A" padding={['16px']}>
              <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']}>
                Invoice Total
              </Text>
            </Th>
            <Th borderBottom="2px solid #D3BB8A" padding={['16px']}>
              <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']}>
                Status
              </Text>
            </Th>
            <Th borderBottom="2px solid #D3BB8A" padding={['16px']}>
              <Text fontFamily="Normal" textTransform="capitalize" fontSize={['15px']}>
                Download
              </Text>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {invoices.map((invoice: Invoice, index: number) => (
            <Tr key={index} padding={['16px']} height={['60px']}>
              <Td padding={['16px']}>
                <Text fontSize={['14px']} fontFamily="Normal">
                  {invoice.name}
                </Text>
              </Td>
              <Td padding={['16px']}>
                <Text fontFamily="Normal" fontSize={['14px']}>
                  {invoice.due_date}
                </Text>
              </Td>
              <Td padding={['16px']}>
                <Text fontFamily="Normal" fontSize={['14px']}>
                  {`$${invoice.total}`}
                </Text>
              </Td>
              <Td padding={['16px']}>
                <Text fontFamily="Normal" fontSize={['14px']}>
                  {invoice.paid ? 'Paid' : 'Pending'}
                </Text>
              </Td>
              <Td>
                <Button
                  variant="link"
                  fontFamily="Metal"
                  fontSize={['15px']}
                  color="white"
                  onClick={() => window.open(invoice.url, '_self')}
                >
                  Download
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {invoices.length === 0 && (
        <Box
          height={['60px']}
          width={['100%']}
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontFamily="Normal"
          fontSize={['14px']}
        >
          No Invoices Yet
        </Box>
      )}
    </TableContainer>
  );
}

export default BillingHistoryTable;
