import { Box } from '@chakra-ui/react';
import MembersTable from '../tables/MembersTable';
import BillingBarActions from '../barActions/BillingBarActions';
import { useUserStore } from '../../store/user';
import TopActionBar from '../common/TopActionBar';
import UserInvitesModal from '../modals/UserInvitesModal';
import React, { useState } from 'react';

type MemberItem = {
  id: string;
  name: string;
  surname: string;
  email: string;
  roles: string;
};

interface MembersProps {
  searchBarText: string;
  members: MemberItem[];
  filteredMembers: MemberItem[];
  searchMembers: (searchString: string) => void;
  // setFilteredMembers: (members: MemberItem[]) => void;
}

function Members({ searchBarText, members, filteredMembers, searchMembers }: MembersProps) {
  const organisation = useUserStore((state) => state.organisation);
  const [userInvitesModalActive, setUserInvitesModalActive] = useState(false);

  return (
    <Box flex="1" flexDirection="column">
      <UserInvitesModal
        userInvitesModalActive={userInvitesModalActive}
        setUserInvitesModalActive={(state) => setUserInvitesModalActive(state)}
      />
      <TopActionBar
        title={organisation.alias}
        subtitle={`Members: ${members.length.toString()}`}
        searchBarPlaceholder="Search Name..."
        showSearchBar
        searchBarText={searchBarText}
        onSearchBarTextChange={(value) => searchMembers(value)}
        rightActions={<BillingBarActions openUserInvitesModal={() => setUserInvitesModalActive(true)} />}
      />
      <Box p={['40px']} width={['100%']}>
        <MembersTable members={filteredMembers} openUserInvitesModal={() => setUserInvitesModalActive(true)} />
      </Box>
    </Box>
  );
}

export default Members;
