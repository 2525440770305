import { Box, Fade, SimpleGrid, Skeleton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useExperiencesStore } from '../store/experiences';
import DeleteExperienceModal from '../components/modals/DeleteExperienceModal';
import RenameExperienceModal from '../components/modals/RenameExperienceModal';
import CreateExperienceModal from '../components/modals/CreateExperienceModal';
import { ExperienceListItem } from '../interfaces';
import { Experiences } from '../Icons/Experiences';
import TopActionBar from '../components/common/TopActionBar';
import ExperiencesBarActions from '../components/barActions/ExperiencesBarActions';
import ExperienceItem from '../components/experiencesView/ExperienceItem';
import CreateExperienceCard from '../components/cards/CreateExperienceCard';
import { useUserStore } from '../store/user';
import configureAxios from '../axiosClient';
import React from 'react';
import ContactModal from '../components/modals/ContactModal';
import UnPublishExperienceModal from '../components/modals/UnPublishExperienceModal';
import UpdateSourceMaterialsModal from '../components/modals/UpdateSourceMaterialsModal';

const axiosClient = configureAxios();

function ExperiencesView() {
  const [searchBarText, setSearchBarText] = useState('');
  const [changeInExperience, setChangeInExperience] = useState(false);
  const [experiencesLoaded, setExperiencesLoaded] = useState(true);
  const [renameModalActive, setRenameModalActive] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [unPublishExperienceModalActive, setUnPublishExperienceModalActive] = useState(false);
  const [createModalActive, setCreateModalActive] = useState(false);

  const [contactModalActive, setContactModalActive] = useState(false);
  const [hoveredExperience, setHoveredExperience] = useState<string | null>(null);
  const [stopFetching, setStopFetching] = useState(false);
  const [updateSourceModalActive, setUpdateSourceModalActive] = useState(false);

  function debounce<T extends (...args: string[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
    let timeoutId: ReturnType<typeof setTimeout>;

    return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const organisation = useUserStore((state) => state.organisation);
  const {
    experiences,
    filteredExperiences,
    setExperiences,
    setSelectedExperience,
    renameExperience,
    deleteExperience,
    unPublishExperience,
    filterExperiences,
    setShowEmbedModal,
  } = useExperiencesStore();

  const debouncedFilter = debounce(filterExperiences, 500);
  const fetchExperiences = async () => {
    const userOrgId = organisation.organizationId;
    if (userOrgId?.length > 0) {
      setExperiencesLoaded(true);
      try {
        const res = await axiosClient.get(`org/${userOrgId}/experiences`);
        const experiencesData = res.data.data;
        const sortedExperiences = experiencesData?.sort(
          (a: ExperienceListItem, b: ExperienceListItem) =>
            new Date(b.created).getTime() - new Date(a.created).getTime(),
        );
        setExperiences(sortedExperiences);
        setExperiencesLoaded(false);
        setChangeInExperience(false);

        if (experiencesData?.length === 0 || experiencesData.every((exp: ExperienceListItem) => exp.processed)) {
          setStopFetching(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    fetchExperiences();

    if (!stopFetching) {
      const intervalId = setInterval(fetchExperiences, 30000);
      return () => clearInterval(intervalId);
    }
  }, [organisation.organizationId, changeInExperience, stopFetching]);

  const openDeleteExperienceModal = (experienceListItem: ExperienceListItem) => {
    setSelectedExperience(experienceListItem);
    setDeleteModalActive(true);
  };

  const unPublishExperienceModal = (experienceListItem: ExperienceListItem) => {
    setSelectedExperience(experienceListItem);
    setUnPublishExperienceModalActive(true);
  };

  const openRenameExperienceModal = (experienceListItem: ExperienceListItem) => {
    setSelectedExperience(experienceListItem);
    setRenameModalActive(true);
  };

  const openEmbedExperienceModal = (experienceListItem: ExperienceListItem) => {
    setSelectedExperience(experienceListItem);
    setShowEmbedModal(true);
  };

  const searchExperiences = (value: string) => {
    setSearchBarText(value);
    debouncedFilter(value);
  };

  const openUpdateSourceMaterialsModal = (experienceListItem: ExperienceListItem) => {
    setSelectedExperience(experienceListItem);
    setUpdateSourceModalActive(true);
  };

  return (
    <Fade in={true} transition={{ enter: { duration: 0.2 } }}>
      <UpdateSourceMaterialsModal
        updateSourceModalActive={updateSourceModalActive}
        setUpdateSourceModalActive={(state: boolean) => setUpdateSourceModalActive(state)}
        setChangeInExperience={(state) => setChangeInExperience(state)}
      />
      <RenameExperienceModal
        renameModalActive={renameModalActive}
        setRenameModalActive={(state: boolean) => setRenameModalActive(state)}
        renameExperience={(updatedExperience) => {
          renameExperience(updatedExperience);
          setRenameModalActive(false);
        }}
      />
      <UnPublishExperienceModal
        unPublishExperienceModalActive={unPublishExperienceModalActive}
        setUnPublishExperienceModalActive={(state: boolean) => setUnPublishExperienceModalActive(state)}
        unPublishExperience={(experienceId: string) => {
          unPublishExperience(experienceId);
          setUnPublishExperienceModalActive(false);
        }}
        fetchExperiences={fetchExperiences}
      />
      <DeleteExperienceModal
        deleteModalActive={deleteModalActive}
        setDeleteModalActive={(state: boolean) => setDeleteModalActive(state)}
        deleteExperience={(experienceId: string) => {
          deleteExperience(experienceId);
          setDeleteModalActive(false);
        }}
      />
      <CreateExperienceModal
        createModalActive={createModalActive}
        setChangeInExperience={(state) => setChangeInExperience(state)}
        setCreateModalActive={(state: boolean) => setCreateModalActive(state)}
      />
      <TopActionBar
        icon={<Experiences stroke="white" boxSize={[5]} />}
        title="Experiences"
        showSearchBar
        searchBarPlaceholder="Search Experience Name..."
        rightActions={<ExperiencesBarActions createExperience={() => setCreateModalActive(true)} />}
        searchBarText={searchBarText}
        onSearchBarTextChange={(value) => searchExperiences(value)}
      />

      <ContactModal contactModalActive={contactModalActive} setContactModalActive={setContactModalActive} />
      <Box p={['40px']}>
        <SimpleGrid columns={[3, 3, 3, 3, 5, 6]} spacing={[5]}>
          {searchBarText?.length > 0 ? (
            filteredExperiences?.length > 0 ? (
              filteredExperiences?.map((experienceListItem: ExperienceListItem) => (
                <Skeleton
                  key={experienceListItem.id}
                  isLoaded={!experiencesLoaded}
                  startColor="#29303C"
                  endColor="transparent"
                  borderRadius="16px"
                >
                  <ExperienceItem
                    key={experienceListItem.id}
                    experienceListItem={experienceListItem}
                    experienceHovered={hoveredExperience === experienceListItem.id}
                    renameExperience={() => openRenameExperienceModal(experienceListItem)}
                    unPublishExperience={() => unPublishExperienceModal(experienceListItem)}
                    deleteExperience={() => openDeleteExperienceModal(experienceListItem)}
                    openEmbedModal={() => openEmbedExperienceModal(experienceListItem)}
                    viewExperience={() => setSelectedExperience(experienceListItem)}
                    hoverExperience={() => setHoveredExperience(experienceListItem.id)}
                    unHoverExperience={() => setHoveredExperience('')}
                    updateSourceMaterials={() => openUpdateSourceMaterialsModal(experienceListItem)}
                  />
                </Skeleton>
              ))
            ) : (
              <Skeleton isLoaded={!experiencesLoaded} startColor="#29303C" endColor="transparent" borderRadius="16px">
                <CreateExperienceCard createExperience={() => setCreateModalActive(true)} />
              </Skeleton>
            )
          ) : experiences?.length > 0 ? (
            experiences?.map((experienceListItem: ExperienceListItem) => (
              <ExperienceItem
                key={experienceListItem.id}
                experienceListItem={experienceListItem}
                experienceHovered={hoveredExperience === experienceListItem.id}
                renameExperience={() => openRenameExperienceModal(experienceListItem)}
                unPublishExperience={() => unPublishExperienceModal(experienceListItem)}
                deleteExperience={() => openDeleteExperienceModal(experienceListItem)}
                openEmbedModal={() => openEmbedExperienceModal(experienceListItem)}
                viewExperience={() => setSelectedExperience(experienceListItem)}
                hoverExperience={() => setHoveredExperience(experienceListItem.id)}
                unHoverExperience={() => setHoveredExperience('')}
                updateSourceMaterials={() => openUpdateSourceMaterialsModal(experienceListItem)}
              />
            ))
          ) : (
            <Skeleton isLoaded={!experiencesLoaded} startColor="#29303C" endColor="transparent" borderRadius="16px">
              <CreateExperienceCard createExperience={() => setCreateModalActive(true)} />
            </Skeleton>
          )}
        </SimpleGrid>
      </Box>
    </Fade>
  );
}

export default ExperiencesView;
