import { Box, Text } from '@chakra-ui/react';
import { useAuthStore } from '../../store/auth';
import LoginForm from '../forms/LoginForm';
import React from 'react';

function Login() {
  const setEnteredEmail = useAuthStore((state) => state.setEnteredEmail);

  return (
    <Box
      w={['100%', '380px', '480px']}
      height={['auto']}
      p="30px"
      borderRadius="16px"
      background="#1D2531"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      gap={5}
    >
      <Text
        fontFamily="Metal"
        fontSize={['30px', '30px', '40px']}
        lineHeight={['30px', '30px', '40px']}
        textAlign="center"
        textTransform="uppercase"
      >
        Welcome
      </Text>

      <LoginForm setEnteredEmail={(email) => setEnteredEmail(email)} />
    </Box>
  );
}

export default Login;
