import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const GoldQuestionMark = (props: IconProps) => (
  <Icon width="8" height="8" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1" y="0.5" width="23" height="23" rx="11.5" fill="white" />
    <rect x="1" y="0.5" width="23" height="23" rx="11.5" stroke="#D6DBE4" />
    <path
      d="M14.8677 9.61842V9.61817C14.868 9.02784 14.6698 8.53664 14.2649 8.11735C13.8887 7.72781 13.3261 7.5 12.4993 7.5C11.8511 7.5 11.3523 7.67672 10.9655 8.00141C10.6722 8.24753 10.4478 8.51862 10.2867 8.81539L10.7384 9.00836C10.8396 8.83476 10.9608 8.66675 11.0996 8.5042C11.45 8.09401 11.9454 7.92105 12.4993 7.92105C13.0231 7.92105 13.4983 8.06767 13.8565 8.41966C14.1999 8.75702 14.3677 9.18685 14.3677 9.67105M14.8677 9.61842L13.8677 9.67105M14.8677 9.61842C14.8677 9.98453 14.7896 10.266 14.6572 10.4849C14.4994 10.7459 14.2363 11.0649 13.8489 11.4434C13.4242 11.8508 13.091 12.2273 12.9542 12.5472C12.8644 12.7575 12.8152 13.0296 12.7922 13.3421H12.2754M14.8677 9.61842L12.2754 13.3421M14.3677 9.67105H13.8677M14.3677 9.67105C14.3677 9.67085 14.3677 9.67066 14.3677 9.67046L13.8677 9.67105M14.3677 9.67105C14.3681 10.0848 14.2341 10.4615 13.9731 10.7827C13.7598 11.0452 13.4631 11.3395 13.0942 11.6636M13.8677 9.67105C13.868 9.96964 13.7738 10.2351 13.5851 10.4674C13.3963 10.6996 13.1221 10.9737 12.7624 11.2895M13.0942 11.6636C13.0948 11.663 13.0954 11.6625 13.0959 11.662L12.7624 11.2895M13.0942 11.6636C12.7078 12.0098 12.5021 12.2891 12.4162 12.4988C12.3515 12.6569 12.2988 12.9296 12.2754 13.3421M13.0942 11.6636C13.0936 11.6641 13.093 11.6646 13.0923 11.6652L12.7624 11.2895M12.7624 11.2895L12.2754 13.3421M12.5017 16.5H12.4993C12.3433 16.5 12.2225 16.4503 12.1097 16.3375C11.9968 16.2246 11.9468 16.1034 11.9466 15.9467C11.9465 15.7911 11.9959 15.6709 12.1088 15.5587C12.223 15.445 12.3449 15.3951 12.5005 15.3947C12.6542 15.3944 12.775 15.4433 12.8894 15.5578C13.0038 15.6721 13.0525 15.7926 13.0519 15.9455C13.0513 16.1014 13.0013 16.2242 12.8873 16.3396C12.7767 16.4516 12.6578 16.5007 12.5017 16.5Z"
      fill="black"
      stroke="#D3BB8A"
    />
  </Icon>
);
