import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import FormWrapper from '../common/FormWrapper';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useUserStore } from '../../store/user';
import React, { useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import configureAxios from '../../axiosClient';
import { dispatchUserToAnalytics } from '../../analytics';
import { useAuthStore } from '../../store/auth';

type ContactModalFormValues = {
  subject: string;
  message: string;
};
interface ContactModalProps {
  postLogin?: boolean;
  contactModalActive: boolean;
  setContactModalActive: (state: boolean) => void;
}

const axiosClient = configureAxios();

function ContactModal({ postLogin = true, contactModalActive, setContactModalActive }: ContactModalProps) {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const { setContactedSales } = useAuthStore();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { organisation, user } = useUserStore();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContactModalFormValues>();

  const onSubmit: SubmitHandler<ContactModalFormValues> = (data) => {
    setIsSubmitting(true);
    const userOrgId = organisation.organizationId;
    axiosClient
      .post(postLogin ? `org/${userOrgId}/email/customer-support` : `/user/email/customer-support`, {
        subject: data.subject,
        body: data.message,
      })
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          setFormSubmitted(true);
          toast({
            title: `Your request was sent successfully.`,
            status: 'success',
          });
          dispatchUserToAnalytics('request_support', {
            organisation_user_id: user.id,
            organisation_id: userOrgId,
          });
          setContactedSales(true);
        } else {
          toast({
            title: `Your request was failed.`,
            status: 'error',
          });
        }
        setIsSubmitting(false);
      })
      .catch((err: unknown) => {
        console.log(err);
        setIsSubmitting(false);
      });
  };

  const close = () => {
    setContactModalActive(false);
    setFormSubmitted(false);
    reset();
  };

  return (
    <Modal isOpen={contactModalActive} onClose={close} isCentered>
      <ModalOverlay />
      <ModalContent
        minW={['600px']}
        height={['auto']}
        padding={['20px']}
        borderRadius="16px"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <CloseIcon
          position="absolute"
          boxSize={[4]}
          cursor="pointer"
          color="#D6DBE4"
          _hover={{ color: 'black' }}
          transition="0.2s all"
          right={['20px']}
          top={['20px']}
          onClick={close}
        />
        <ModalBody>
          <Collapse in={!formSubmitted} animateOpacity>
            <Box display="flex" flexDirection="column" alignItems="center" gap={5}>
              <Text
                color="#08101D"
                fontFamily="Metal"
                fontSize={['35px']}
                lineHeight={['35px']}
                textAlign="center"
                textTransform="uppercase"
              >
                Contact us
              </Text>

              <FormWrapper onSubmit={handleSubmit(onSubmit)} name="request_support">
                <Box
                  w={['100%']}
                  height={['auto']}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={5}
                >
                  <Box display="flex" gap={5} width="100%" flexWrap="wrap">
                    <FormControl isInvalid={errors.subject ? true : false} isRequired>
                      <FormLabel color="rgba(127, 139, 158, 1)" fontFamily="Normal" fontSize="12px" margin="0px">
                        Subject
                      </FormLabel>
                      <Input
                        id="name"
                        placeholder="enter subject"
                        color="#58677E"
                        borderBottom="1px solid #D6DBE4"
                        variant="flushed"
                        fontFamily="Normal"
                        fontSize={['14px', '14px', '15px']}
                        size="md"
                        _placeholder={{ color: '#7F8B9E' }}
                        errorBorderColor="#EA1A4C"
                        {...register('subject', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                          maxLength: { value: 30, message: 'Maximum length should be 30' },
                        })}
                      />
                      <FormErrorMessage color="#EA1A4C" mt={['4px']} fontSize="13px" fontFamily="Normal">
                        {errors.subject && errors.subject.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.message ? true : false} isRequired>
                      <FormLabel color="rgba(127, 139, 158, 1)" fontFamily="Normal" fontSize="12px">
                        Message
                      </FormLabel>
                      <Textarea
                        id="message"
                        placeholder="enter message"
                        minHeight="100px"
                        maxHeight="200px"
                        color="#58677E"
                        border="1px solid rgba(211, 187, 138, 1) !important"
                        _focusVisible={{
                          border: 'none',
                        }}
                        borderRadius="16px"
                        variant="outline"
                        fontFamily="Normal"
                        fontSize={['14px', '14px', '15px']}
                        errorBorderColor="#EA1A4C"
                        _placeholder={{ color: '#7F8B9E' }}
                        {...register('message', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />

                      <FormErrorMessage color="#EA1A4C" mt={['4px']} fontSize="13px" fontFamily="Normal">
                        {errors.message && errors.message.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>

                  <Button
                    textTransform="uppercase"
                    width={['80%', '80%', '300px']}
                    variant="outline"
                    color="#08101D"
                    background="white"
                    fontFamily="Metal"
                    fontSize={['14px', '14px', '14px']}
                    borderRadius="30px"
                    border="1px solid #D6DBE4"
                    _hover={{
                      bg: '#D3BB8A',
                      borderColor: '#D3BB8A',
                    }}
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Send
                  </Button>
                </Box>
              </FormWrapper>

              <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                <Button
                  fontSize={['11px']}
                  variant="link"
                  fontFamily="Normal"
                  textAlign="center"
                  mt={['15px']}
                  onClick={close}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Collapse>
          <Collapse in={formSubmitted} animateOpacity>
            <Box display="flex" flexDirection="column" alignItems="center" gap={5}>
              <Text fontFamily="Bold" fontSize="15px" color="rgba(6, 255, 71, 1)" textAlign="center">
                Completed
              </Text>

              <Box>
                <Text fontFamily="Bold" fontSize="15px" color="rgba(8, 16, 29, 1)" textAlign="center">
                  Your concern has been sent.
                </Text>
                <Text fontFamily="Normal" fontSize="15px" color="rgba(8, 16, 29, 1)" textAlign="center">
                  Our support team will be in touch shortly to discuss your concern.
                </Text>
              </Box>

              <Button
                textTransform="uppercase"
                width={['80%', '80%', '300px']}
                variant="outline"
                color="#08101D"
                background="white"
                fontFamily="Metal"
                fontSize={['14px', '14px', '14px']}
                borderRadius="30px"
                border="1px solid #D6DBE4"
                _hover={{
                  bg: '#D3BB8A',
                  borderColor: '#D3BB8A',
                }}
                onClick={close}
              >
                Close
              </Button>
            </Box>
          </Collapse>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ContactModal;
