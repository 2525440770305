import { Box, IconButton, Image, Spinner, Text, useToast } from '@chakra-ui/react';
import { ExperienceListItem } from '../../interfaces';
import { Copy } from '../../Icons/Copy';
import { Eye } from '../../Icons/Eye';
import DummyExperienceImage from '../../assets/image/DummyExperienceImage.jpeg';
import ExperienceItemAdjustMenu from './ExperienceItemAdjustMenu';
import { useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { dispatchExperienceToAnalytics } from '../../analytics';
import { useUserStore } from '../../store/user';

interface ExperienceItemProps {
  experienceListItem: ExperienceListItem;
  experienceHovered: boolean;
  renameExperience: () => void;
  deleteExperience: () => void;
  unPublishExperience: () => void;
  openEmbedModal: () => void;
  viewExperience: () => void;
  hoverExperience: () => void;
  unHoverExperience: () => void;
  updateSourceMaterials: () => void;
}

function ExperienceItem({
  experienceListItem,
  experienceHovered,
  renameExperience,
  deleteExperience,
  unPublishExperience,
  openEmbedModal,
  hoverExperience,
  unHoverExperience,
  updateSourceMaterials,
}: ExperienceItemProps) {
  const navigate = useNavigate();
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const [isOpen, setOpen] = useState(false);
  const { organisation, user } = useUserStore();

  const handleCopy = async (text: string) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      }
      if (!toast.isActive('expereinceLink')) {
        toast({
          id: 'expereinceLink',
          title: `Link Copied`,
          status: 'success',
        });
      }
    } catch (err) {
      console.error('Async: Could not copy text', err);
    }
  };

  return (
    <Box
      key={experienceListItem.id}
      height={['auto']}
      width={['100%']}
      borderRadius="16px"
      bg="#1D2531"
      position="relative"
    >
      {!experienceListItem.processed && (
        <Box
          bg="rgba(8, 16, 29, 0.6)"
          position="absolute"
          height="100%"
          width="100%"
          borderRadius="16px"
          zIndex="299"
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Text>Processing</Text>
          <Spinner size="md" color="white" />
        </Box>
      )}

      <Box overflow="hidden" width={['100%']} height={['140px']} borderTopRadius="16px">
        <Image
          src={
            experienceListItem.preview_url
              ? experienceListItem.preview_url.replace('index.html', 'browser_icon.jpg')
              : DummyExperienceImage
          }
          objectFit="cover"
          width={['100%']}
          height={['100%']}
          transform={experienceHovered ? 'scale(1.2)' : 'scale(1)'}
          transition="0.2s all"
          onMouseEnter={hoverExperience}
          cursor="pointer"
        />
      </Box>

      <Box
        opacity={experienceHovered ? 1 : 0}
        visibility={experienceHovered ? 'visible' : 'hidden'}
        backgroundColor="rgba(0,0,0,0.5)"
        transition="0.2s all"
        background="rgba(8, 16, 29, 0.60)"
        backdropFilter="blur(4px)"
        position="absolute"
        zIndex="2"
        top="0px"
        width={['100%']}
        height={['140px']}
        borderTopRadius="16px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        cursor="pointer"
        onMouseLeave={() => {
          unHoverExperience();
          setOpen(false);
        }}
      >
        <Box
          height={['70px']}
          width={['90px']}
          display="flex"
          justifyContent={experienceListItem.processed ? 'space-between' : 'center'}
          alignItems="flex-end"
        >
          {experienceListItem.processed && (
            <IconButton
              bg="white"
              _hover={{
                bg: '#D3BB8A',
              }}
              borderRadius="100%"
              icon={<Eye boxSize={[5]} />}
              aria-label="View Experience"
              onClick={() => {
                navigate(`/viewer/${experienceListItem.id}`);
                dispatchExperienceToAnalytics('view_experience', {
                  experience_id: experienceListItem.id,
                  experience_name: experienceListItem.name,
                  experience_draft_url: experienceListItem.preview_url,
                  experience_published_url: experienceListItem.published_url,
                  organisation_user_id: user.id,
                  organisation_id: organisation.organizationId,
                });
              }}
            />
          )}
          <ExperienceItemAdjustMenu
            isOpen={isOpen}
            isPublished={experienceListItem?.published_url?.length > 0}
            renameExperience={renameExperience}
            unPublishExperience={unPublishExperience}
            deleteExperience={deleteExperience}
            openEmbedModal={openEmbedModal}
            setOpen={(state) => setOpen(state)}
            updateSourceMaterials={updateSourceMaterials}
          />
        </Box>
      </Box>
      <Box
        width={['100%']}
        height={['auto']}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        padding={['12px 16px']}
      >
        <Text fontFamily="Normal" fontSize={['14px']} noOfLines={1}>
          {experienceListItem.name}
        </Text>
        {experienceListItem.published_url.length > 0 ? (
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Text fontFamily="Normal" fontSize={['11px']} color="#4DFF7B" maxW="80%" noOfLines={1}>
              {experienceListItem.published_url}
            </Text>
            <Copy
              boxSize={[4]}
              stroke="white"
              _hover={{ stroke: '#4DFF7B' }}
              cursor="pointer"
              transition="0.2s all"
              onClick={() => handleCopy(experienceListItem.published_url)}
            />
          </Box>
        ) : (
          <Text fontFamily="Normal" fontSize={['11px']}>
            Not Published
          </Text>
        )}
      </Box>
    </Box>
  );
}

export default ExperienceItem;
