import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type {} from '@redux-devtools/extension';
import { Organisation, User } from '../interfaces';
import configureAxios from '../axiosClient';

const axiosClient = configureAxios();
interface UserState {
  user: User;
  organisation: Organisation;
  roles: string;
  setUser: (user: User) => void;
  getUser: () => void;
  getOrganizations: () => void;
  resetUser: () => void;
}

const defaultUser = {
  id: '',
  image: '',
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: '',
  roles: '',
};

const defaultOrganisation = {
  organizationId: '',
  alias: '',
  roles: '',
};

export const useUserStore = create<UserState>()(
  devtools(
    persist(
      (set) => ({
        user: defaultUser,
        organisation: defaultOrganisation,
        roles: '',
        setUser: (user: User) => set(() => ({ user: user })),
        getUser: () => {
          axiosClient
            .get(`user/profile`)
            .then((res) => {
              set(() => ({
                user: {
                  id: res.data.data.uuid,
                  firstName: res.data.data.name,
                  lastName: res.data.data.surname,
                  email: res.data.data.email,
                  dateOfBirth: '',
                  roles: '',
                },
              }));
            })
            .catch((err: unknown) => {
              console.log(err);
            });
        },
        getOrganizations: () => {
          axiosClient
            .get(`user/organisations`)
            .then((res) => {
              const org = res.data.data[0];
              set((state) => ({
                roles: org?.roles,
                organisation: {
                  ...state.organisation,
                  organizationId: org.org_id,
                  alias: org.alias,
                },
              }));
            })
            .catch((err: unknown) => {
              console.log(err);
            });
        },
        resetUser: () =>
          set(() => ({
            user: {
              id: '',
              image: '',
              firstName: '',
              lastName: '',
              email: '',
              dateOfBirth: '',
              roles: '',
            },
            organisation: {
              organizationId: '',
              alias: '',
              roles: '',
            },
            roles: '',
          })),
      }),
      {
        name: 'user-storage',
      },
    ),
  ),
);
