import { Box, Spinner, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import configureAxios from '../axiosClient';
import { useUserStore } from '../store/user';
import { useExperiencesStore } from '../store/experiences';
import UnPublishExperienceModal from '../components/modals/UnPublishExperienceModal';

const axiosClient = configureAxios();

function Viewer() {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const navigate = useNavigate();
  const { experienceId } = useParams();
  const { organisation } = useUserStore();
  const {
    selectedExperience,
    setSelectedExperience,
    unPublishExperience,
    viewerUnPublishExperienceModalActive,
    setViewerUnPublishExperienceModalActive,
  } = useExperiencesStore();
  const [experienceLoading, setExperienceLoading] = useState<boolean>(true);

  useEffect(() => {
    const userOrgId = organisation.organizationId;
    axiosClient
      .get(`org/${userOrgId}/experiences/${experienceId}`)
      .then((res) => {
        if (res.data.status === 'OK') {
          setSelectedExperience(res.data.data);
          setExperienceLoading(false);
        } else {
          navigate('/');
          if (!toast.isActive('experienceErrorOne')) {
            toast({
              id: 'experienceErrorOne',
              title: `Experience does not exist`,
              status: 'info',
            });
          }
        }
      })
      .catch((err: unknown) => {
        console.log(err);
        navigate('/');
        if (!toast.isActive('experienceErrorTwo')) {
          toast({
            id: 'experienceErrorTwo',
            title: `Experience does not exist`,
            status: 'info',
          });
        }
      });
  }, []);

  const onIframeLoaded = () => {
    setExperienceLoading(false);
  };

  return (
    <Box w="100%" h="100%" display="flex" justifyContent="center" alignItems="center" position="fixed">
      {experienceLoading ? (
        <Spinner position="fixed" top={0} left={0} right={0} bottom={0} margin="auto" />
      ) : (
        <Box
          id="viewer-iframe"
          flex="1"
          w="100%"
          h="100%"
          as="iframe"
          title="Experience"
          src={selectedExperience?.preview_url}
          onLoad={selectedExperience?.preview_url?.length > 0 ? onIframeLoaded : undefined}
        />
      )}
      <UnPublishExperienceModal
        unPublishExperienceModalActive={viewerUnPublishExperienceModalActive}
        setUnPublishExperienceModalActive={(state: boolean) => setViewerUnPublishExperienceModalActive(state)}
        unPublishExperience={(experienceId: string) => {
          unPublishExperience(experienceId);
          setViewerUnPublishExperienceModalActive(false);
        }}
      />
    </Box>
  );
}

export default Viewer;
