import { MutableRefObject, useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Progress,
  Text,
  useToast,
} from '@chakra-ui/react';
import FormWrapper from '../common/FormWrapper';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Folder } from '../../Icons/Folder';
import { useUserStore } from '../../store/user';
import React from 'react';
import configureAxios from '../../axiosClient';
import { dispatchExperienceToAnalytics } from '../../analytics';

type CreateExperienceFormValues = {
  experienceName: string;
  sceneName: string;
  payload: FileList;
};

interface CreateExperienceFormProps {
  createExperience: (expName: string) => void;
  setCreatingExperience: (state: boolean) => void;
  setChangeInExperience: (state: boolean) => void;
}

const axiosClient = configureAxios();

function CreateExperienceForm({
  createExperience,
  setCreatingExperience,
  setChangeInExperience,
}: CreateExperienceFormProps) {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const { organisation, user } = useUserStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [totalProgressCompleted, setTotalProgressCompleted] = useState(0);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreateExperienceFormValues>();

  const watchFile = watch('payload') as FileList;
  const payLoadFile = watchFile ? watchFile[0] : null;

  const { ref, ...fields } = register('payload', { required: 'Payload Is Required' });
  const hiddenInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  const onUpload = () => {
    if (hiddenInputRef.current) {
      hiddenInputRef?.current?.click();
    } else {
      console.error('hiddenInputRef.current is null');
    }
  };

  const onSubmit: SubmitHandler<CreateExperienceFormValues> = (data) => {
    setIsSubmitting(true);
    const userOrgId = organisation.organizationId;

    const file = data.payload ? data.payload[0] : null;

    if (!file) {
      console.error('No file selected or payload is not an array');
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append('payload', file);

    axiosClient
      .post(
        process.env.REACT_APP_API_URL +
          `experience/create/${userOrgId}/${encodeURIComponent(data.experienceName)}/${data.sceneName}`,
        formData,
        {
          onUploadProgress: (ProgressEvent) => {
            if (ProgressEvent.total) {
              const progressCompleted = Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total);
              setTotalProgressCompleted(progressCompleted);
            }
          },
        },
      )
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        setIsSubmitting(false);

        if (isStatusOk) {
          dispatchExperienceToAnalytics('create_experience', {
            organisation_id: userOrgId,
            experience_name: data.experienceName,
            organisation_user_id: user.id,
            experience_id: res.data.data.experience_id,
            experience_draft_url: res.data.data.experience_url,
          });
          setCreatingExperience(true);
          createExperience(data.experienceName);
          setChangeInExperience(true);
          setValue('experienceName', '');
          setValue('sceneName', '');
        } else {
          toast({
            title: res.data.data,
            status: 'error',
          });
        }
      })
      .catch((err: unknown) => {
        toast({
          title: `There was an error creating experience`,
          status: 'error',
        });
        setIsSubmitting(false);
        console.log(err);
      });
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)} name="create_experience">
      <Box
        margin={['20px 0px']}
        w={['100%']}
        height={['auto']}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        gap={6}
      >
        <Box
          w={['100%']}
          height="auto"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          gap={5}
        >
          <FormControl isInvalid={errors.experienceName ? true : false} isRequired>
            <FormLabel color="rgba(127, 139, 158, 1)" fontFamily="Normal" fontSize="12px" margin="0px">
              Experience Name
            </FormLabel>
            <Input
              id="experienceName"
              placeholder="enter experience name"
              color="#58677E"
              borderBottom="1px solid #D6DBE4"
              variant="flushed"
              fontFamily="Normal"
              fontSize={['14px', '14px', '14px']}
              size="md"
              _placeholder={{ color: '#7F8B9E' }}
              errorBorderColor="#EA1A4C"
              {...register('experienceName', {
                required: 'This is required',
                minLength: { value: 4, message: 'Minimum length should be 4' },
                maxLength: { value: 20, message: 'Maximum length should be 20' },
              })}
              isDisabled={isSubmitting}
            />
            <FormErrorMessage color="#EA1A4C" mt={['4px']} fontSize="13px" fontFamily="Normal">
              {errors.experienceName && errors.experienceName.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.sceneName ? true : false} isRequired>
            <FormLabel color="rgba(127, 139, 158, 1)" fontFamily="Normal" fontSize="12px" margin="0px">
              Scene Name
            </FormLabel>
            <Input
              id="sceneName"
              placeholder="enter scene name"
              color="#58677E"
              borderBottom="1px solid #D6DBE4"
              variant="flushed"
              fontFamily="Normal"
              fontSize={['14px', '14px', '14px']}
              size="md"
              _placeholder={{ color: '#7F8B9E' }}
              errorBorderColor="#EA1A4C"
              {...register('sceneName', {
                required: 'This is required',
                minLength: { value: 4, message: 'Minimum length should be 4' },
                maxLength: { value: 15, message: 'Maximum length should be 15' },
                pattern: {
                  value: /^\S*$/,
                  message: 'No white spaces allowed',
                },
              })}
              isDisabled={isSubmitting}
            />
            <FormErrorMessage color="#EA1A4C" mt={['4px']} fontSize="13px" fontFamily="Normal">
              {errors.sceneName && errors.sceneName.message}
            </FormErrorMessage>
          </FormControl>
        </Box>

        <Box
          w={['100%']}
          height={['auto']}
          // mt={['30px']}
          padding={['20px']}
          borderRadius="30px"
          border="1px solid #D3BB8A"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Text fontFamily="Normal" color="#08101D" fontSize={['15px']} fontWeight="700" textTransform="capitalize">
              Upload a payload file
            </Text>
            <Text fontFamily="Normal" color="#08101D" fontSize={['11px']}>
              Your Payload file should be a Zip Folder of Rendered Panoramas and generated JSON file.
            </Text>
          </Box>

          <FormControl isInvalid={errors.payload ? true : false} mb="10px">
            <Input
              display="none"
              type="file"
              accept=".zip,.rar,.7zip"
              id="payload"
              {...fields}
              ref={(instance) => {
                ref(instance); // RHF wants a reference to this input
                hiddenInputRef.current = instance; // We also need it to manipulate the element
              }}
              isDisabled={isSubmitting}
            />
            <Box
              width={['100%']}
              height={['auto']}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              gap={2}
            >
              <Box mt="10px" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                {watchFile && payLoadFile && (
                  <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                    <Text fontFamily="Normal" color="#08101D" fontWeight="900" fontSize={['14px']}>
                      File Selected
                    </Text>
                    <Text fontFamily="Normal" color="#08101D" fontSize={['12px']} noOfLines={1} maxWidth="300px">
                      {payLoadFile?.name}
                    </Text>
                  </Box>
                )}
              </Box>

              <FormErrorMessage
                color="#EA1A4C"
                mt={['4px']}
                fontSize="13px"
                fontFamily="Normal"
                justifyContent="center"
              >
                {errors.payload && errors.payload.message}
              </FormErrorMessage>
              <Button
                mt={['10px']}
                leftIcon={<Folder boxSize={[4]} />}
                textTransform="uppercase"
                width={['200px', '200px', '200px']}
                padding={['12px 24px']}
                variant="outline"
                color="#08101D"
                background="white"
                fontFamily="Metal"
                fontSize={['14px', '14px', '14px']}
                borderRadius="30px"
                border="1px solid #D6DBE4"
                _hover={{
                  bg: '#D3BB8A',
                  borderColor: '#D3BB8A',
                }}
                onClick={onUpload}
                isDisabled={isSubmitting}
              >
                Select a file
              </Button>
            </Box>
          </FormControl>
        </Box>

        {isSubmitting && (
          <Text fontFamily="Normal" color="#08101D" fontSize={['12px']}>
            {`Payload is uploading, please don't close the window.`}
          </Text>
        )}

        {isSubmitting && (
          <Progress
            position="relative"
            mt="10px"
            w={['100%']}
            size="sm"
            hasStripe
            value={totalProgressCompleted}
            borderRadius="30px"
            colorScheme="yellow"
            height="16px"
          >
            <Box
              position="absolute"
              width="100%"
              textAlign="center"
              color="black"
              top="0px"
              fontSize="10px"
              fontWeight="bold"
              zIndex="99"
            >
              {totalProgressCompleted === 100 ? 'Processing...' : `${totalProgressCompleted}%`}
            </Box>
          </Progress>
        )}
        <Button
          textTransform="uppercase"
          width={['80%', '80%', '300px']}
          variant="outline"
          color="#08101D"
          background="white"
          fontFamily="Metal"
          fontSize={['14px', '14px', '14px']}
          borderRadius="30px"
          border="1px solid #D6DBE4"
          _hover={{
            bg: '#D3BB8A',
            borderColor: '#D3BB8A',
          }}
          isLoading={isSubmitting}
          type="submit"
        >
          Create
        </Button>
      </Box>
    </FormWrapper>
  );
}

export default CreateExperienceForm;
