import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Bin = (props: IconProps) => (
  <Icon width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.29653 4.02881L7.7269 10.675C7.68648 11.1423 7.30756 11.4998 6.8516 11.4998H3.14455C2.68985 11.4998 2.30968 11.1423 2.26926 10.675L1.70215 4.02881"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.17188 2.54498V2.21532C3.17188 1.604 3.50658 1.03942 4.04591 0.752707C4.70269 0.405368 5.35948 0.415473 6.01626 0.786809C6.52654 1.07478 6.8284 1.628 6.8284 2.21406V2.54498"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path d="M9.0915 2.85303H0.908203" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
