import { Box, Button } from '@chakra-ui/react';
// import { GridIcon } from '../../Icons/GridIcon';
// import { ListView } from '../../Icons/ListView';
import { Plus } from '../../Icons/Plus';
import React from 'react';

interface ExperiencesBarActionsProps {
  createExperience: () => void;
}

function ExperiencesBarActions({ createExperience }: ExperiencesBarActionsProps) {
  return (
    <Box display="flex" gap={4} alignItems="center">
      {/* <GridIcon
        stroke="white"
        _hover={{
          stroke: '#D3BB8A',
        }}
        transition="0.2s all"
        boxSize={[5]}
        cursor="pointer"
      />
      <ListView boxSize={[5]} cursor="pointer" /> */}
      <Button
        leftIcon={<Plus stroke="currentColor" boxSize={[2]} />}
        textTransform="uppercase"
        minW={['100px', '100px', 'auto']}
        variant="outline"
        color="#08101D"
        background="white"
        border="1px solid rgba(242, 243, 245, 0.14)"
        fontFamily="Metal"
        fontSize={['14px', '14px', '14px']}
        borderRadius="30px"
        _hover={{
          bg: '#D3BB8A',
        }}
        onClick={createExperience}
      >
        New Experience
      </Button>
    </Box>
  );
}

export default ExperiencesBarActions;
