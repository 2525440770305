import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from 'axios';
import { useAuthStore } from './store/auth';
import { useUserStore } from './store/user';
import { useExperiencesStore } from './store/experiences';
import { usePlanStore } from './store/plan';
// import Cookies from 'js-cookie';

interface ErrorResponse {
  message: string;
  data: string;
  status: number | string;
}
const configureAxios = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  });

  instance.interceptors.request.use(
    async (config: AxiosRequestConfig<unknown>) => {
      // check if access token is present in cookies
      // const accessToken = Cookies.get('access_token');
      // if (!accessToken) {
      //   try {
      //     await axios.get(process.env.REACT_APP_API_URL + 'auth/refresh', {
      //       withCredentials: true,
      //     });
      //     return config;
      //   } catch (error) {
      //     console.log(error);
      //     // return config;
      //   }
      // }
      return config;
    },
    (error: AxiosError<ErrorResponse>) => {
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (response) => {
      if (response.data.status === 'BAD' && response.data.data === 'Failed to authenticate user.') {
        const { resetPlans } = usePlanStore.getState();
        const { resetExperiences } = useExperiencesStore.getState();
        const { resetUser } = useUserStore.getState();
        const { resetAuth } = useAuthStore.getState();
        resetAuth();
        resetUser();
        resetExperiences();
        resetPlans();
        response.data.status = 'LOGOUT';
      }
      return response;
    },
    (error: AxiosError<ErrorResponse>) => {
      if (error.response) {
        console.error('Error response:', error.response.data);
        if (error.response.data.status === 'BAD' && error.response.data.data === 'Failed to authenticate user.') {
          const { resetPlans } = usePlanStore.getState();
          const { resetExperiences } = useExperiencesStore.getState();
          resetExperiences();
          resetPlans();
          // error.response.data.status = 'LOGOUT';
        }
        return error;
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
      return Promise.reject(error);
    },
  );

  return instance;
};

export default configureAxios;
