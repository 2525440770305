import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const BlackBin = (props: IconProps) => (
  <Icon width="17" height="19" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.7975 6.0293L11.2279 12.6755C11.1875 13.1428 10.8085 13.5002 10.3526 13.5002H6.64553C6.19083 13.5002 5.81065 13.1428 5.77023 12.6755L5.20312 6.0293"
      stroke="#08101D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.67188 4.54498V4.21532C6.67188 3.604 7.00658 3.03942 7.54591 2.75271C8.20269 2.40537 8.85948 2.41547 9.51626 2.78681C10.0265 3.07478 10.3284 3.628 10.3284 4.21406V4.54498"
      stroke="#08101D"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path d="M12.5915 4.85254H4.4082" stroke="#08101D" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
