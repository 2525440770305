import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Input,
  Link,
  Spinner,
  useToast,
  Text,
} from '@chakra-ui/react';
import FormWrapper from '../common/FormWrapper';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useState } from 'react';
import { useAuthStore } from '../../store/auth';
import configureAxios from '../../axiosClient';
import React from 'react';

const axiosClient = configureAxios();

type LoginFormValues = {
  email: string;
  checkBoxOne: boolean;
};

interface LoginFormProps {
  setEnteredEmail: (email: string) => void;
}

function LoginForm({ setEnteredEmail }: LoginFormProps) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormValues>();

  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const { setUserExists, setActiveLoginView } = useAuthStore();
  const [loginLoading, setLoginLoading] = useState(false);

  const onSubmit: SubmitHandler<LoginFormValues> = (data) => {
    setLoginLoading(true);
    setEnteredEmail(data.email);

    axiosClient
      .post(`auth/login`, {
        email: data.email,
      })
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        const doesUserAlreadyExists = res.data.data.userExists === true;

        setUserExists(doesUserAlreadyExists);
        setLoginLoading(false);

        if (isStatusOk) {
          setValue('email', '');
          setValue('checkBoxOne', false);
          setActiveLoginView('enterCode');
        } else {
          toast({
            title: `There was an error logging in`,
            status: 'error',
          });
        }
      })
      .catch((err: unknown) => {
        console.log(err);
        toast({
          title: `There was an error logging in`,
          status: 'error',
        });
        setLoginLoading(false);
      });
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)} name="login">
      {loginLoading ? (
        <Box w={['100%']} height={['100px']} display="flex" justifyContent="center" alignItems="center">
          <Spinner size="md" />
        </Box>
      ) : (
        <Box
          w={['100%']}
          height={['auto']}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          gap={5}
        >
          <FormControl isInvalid={errors.email ? true : false} mb="10px">
            <Input
              id="email"
              placeholder="enter your email"
              color="white"
              borderBottom="1px solid #D6DBE4"
              variant="flushed"
              fontFamily="Normal"
              fontSize={['14px', '14px', '14px']}
              size="md"
              _placeholder={{ color: '#7F8B9E' }}
              errorBorderColor="#EA1A4C"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Entered value does not match email format',
                },
              })}
            />
            <FormErrorMessage color="#EA1A4C" mt={['4px']} fontSize="13px" fontFamily="Normal">
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.checkBoxOne ? true : false} isRequired>
            <Checkbox
              id="checkBoxOne"
              colorScheme="blue"
              {...register('checkBoxOne', {
                required: true,
              })}
            >
              <Text fontFamily="Normal" fontSize="11px" color="white" textAlign="left" lineHeight="15px">
                By checking this box you agree to the Emperia’s&nbsp;
                <Link
                  target="_blank"
                  href="https://emperiavr.com/wp-content/uploads/2022/08/terms-of-use-2022.pdf"
                  textDecoration="underline"
                >
                  Terms of Service
                </Link>
                &nbsp;and&nbsp;
                <Link
                  display={['unset', 'unset', 'none']}
                  target="_blank"
                  href="http://emperiavr.com/wp-content/uploads/2022/08/privacy-policy-2022.pdf"
                  textDecoration="underline"
                >
                  Privacy Policy
                </Link>
              </Text>
              <Text fontFamily="Normal" fontSize="11px" color="white" textAlign="left" lineHeight="15px">
                <Link
                  display={['none', 'none', 'unset']}
                  target="_blank"
                  href="http://emperiavr.com/wp-content/uploads/2022/08/privacy-policy-2022.pdf"
                  textDecoration="underline"
                >
                  Privacy Policy
                </Link>
              </Text>
            </Checkbox>
          </FormControl>
          <Button
            textTransform="uppercase"
            width={['80%', '80%', '300px']}
            variant="outline"
            color="#7F8B9E"
            background="#1D2531"
            border="1px solid rgba(242, 243, 245, 0.14)"
            fontFamily="Metal"
            fontSize={['14px', '14px', '15px']}
            borderRadius="30px"
            _hover={{
              bg: 'white',
              color: '#08101D',
            }}
            isLoading={isSubmitting}
            type="submit"
          >
            Next
          </Button>
        </Box>
      )}
    </FormWrapper>
  );
}

export default LoginForm;
