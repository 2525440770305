const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getNextBillDate = (date: string, interval: string) => {
  const startDate = new Date(date);
  startDate.setMonth(startDate.getMonth() + (interval === 'monthly' ? 1 : 12));
  const month = monthNames[startDate.getMonth()];
  const day = startDate.getDate();
  const year = startDate.getFullYear();
  const formattedDate = day + ' ' + month + ' ' + year;
  return formattedDate;
};

export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

export function formatDate(dateString: string | undefined) {
  if (!dateString) {
    return 'Invalid date';
  }

  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}`;
}

export function calculateTimeElapsed(createdDate: string | undefined) {
  if (!createdDate) {
    return 'Invalid date';
  }

  const currentDate = new Date();
  const parsedCreatedDate = new Date(createdDate);

  const elapsedTime = currentDate.getTime() - parsedCreatedDate.getTime();

  const days = Math.floor(elapsedTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor((elapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

  let formattedTime = '';

  if (days > 0) {
    formattedTime += `${days}d `;
  }

  formattedTime += `${hours < 10 ? '0' + hours : hours}:`;
  formattedTime += `${minutes < 10 ? '0' + minutes : minutes}:`;
  formattedTime += `${seconds < 10 ? '0' + seconds : seconds}`;

  return formattedTime;
}

export function formatDuration(duration: string): string {
  const regex = /^P(?:([0-9]+)D)?T(?:([0-9]+)H)?(?:([0-9]+)M)?([0-9.]+)S$/;
  const matches = duration.match(regex);

  if (!matches) {
    throw new Error('Invalid duration format');
  }

  const days = matches[1] ? parseInt(matches[1], 10) : 0;
  const hours = matches[2] ? parseInt(matches[2], 10) : 0;
  const minutes = matches[3] ? parseInt(matches[3], 10) : 0;
  const seconds = Math.floor(parseFloat(matches[4]));

  let formatted = '';

  if (days > 0) {
    formatted += `${days}D, `;
  }

  formatted += `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
    2,
    '0',
  )}`;

  return formatted;
}
