import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Play = (props: IconProps) => (
  <Icon width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 1.19926C1 1.04428 1.16901 0.948339 1.30129 1.02952L10.713 7.14525C11.3189 7.539 11.32 8.42575 10.715 8.82097L1.30128 14.9705C1.16901 15.0517 0.999999 14.9557 0.999999 14.8007L1 1.19926Z"
      stroke="#08101D"
      strokeWidth="1.6"
    />
  </Icon>
);
