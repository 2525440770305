import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const InternalRedirect = () => {
  useEffect(() => {
    window.location.href = '/?visitor_type=internal';
  }, []);
  return <NavLink to="/?visitor_type=internal" />;
};

export default InternalRedirect;
