import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { RightStemArrow } from '../../Icons/RightStemArrow';

interface UpdateSceneGuideContentProps {
  link: string;
}

const UpdateSceneGuideContent = ({ link }: UpdateSceneGuideContentProps) => {
  return (
    <Box p={'8px'}>
      <Text fontFamily="Normal" color="#08101D" fontSize="14px" fontWeight="400">
        Not sure how?
      </Text>
      <Text fontFamily="Normal" color="#08101D" fontSize="11px" fontWeight="400" mt={'8px'}>
        Visit our documentation for a step-by-step guide.
      </Text>
      <Text
        fontFamily="Normal"
        color="#08101D"
        fontSize="11px"
        fontWeight="400"
        mt={'16px'}
        _hover={{ textDecoration: 'underline' }}
        onClick={() => window.open(link, '_blank')}
      >
        Documentation / Getting Started <RightStemArrow boxSize={[2.5]} fill={'#08101D'} transform="rotate(-45deg)" />
      </Text>
    </Box>
  );
};

export default UpdateSceneGuideContent;
