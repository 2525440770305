import { Box } from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import { Question } from '../../Icons/Question';

const UpdateSceneGuide = forwardRef<HTMLDivElement>((props, ref) => (
  <Box
    w={['25px']}
    h={['25px']}
    border={'1px solid #D6DBE4'}
    borderRadius={'50%'}
    display={'flex'}
    justifyContent="center"
    alignItems="center"
    position={'absolute'}
    top={'15px'}
    right={'15px'}
    transition={'0.2s all'}
    _hover={{
      background: '#D6DBE4',
      cursor: 'pointer',
    }}
    ref={ref}
    {...props}
  >
    <Question strokeWidth={'2.4'} boxSize={[2]} />
  </Box>
));

UpdateSceneGuide.displayName = 'UpdateSceneGuide';

export default UpdateSceneGuide;
