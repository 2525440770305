import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Star = (props: IconProps) => (
  <Icon width="13" height="19" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.30979 0.879835C6.36966 0.695576 6.63034 0.695572 6.69021 0.879837L7.78098 4.23688C7.91486 4.6489 8.29881 4.92786 8.73204 4.92786H12.2618C12.4556 4.92786 12.5361 5.17579 12.3794 5.28967L9.52373 7.36444C9.17324 7.61908 9.02658 8.07045 9.16046 8.48247L10.2512 11.8395C10.3111 12.0238 10.1002 12.177 9.94346 12.0631L7.08778 9.98835C6.7373 9.73371 6.2627 9.73371 5.91221 9.98835L3.05654 12.0631C2.8998 12.177 2.6889 12.0238 2.74877 11.8395L3.83954 8.48247C3.97342 8.07045 3.82676 7.61908 3.47627 7.36444L0.6206 5.28967C0.463859 5.17579 0.54441 4.92786 0.738158 4.92786H4.26796C4.70119 4.92786 5.08514 4.6489 5.21902 4.23688L6.30979 0.879835Z"
      stroke="#08101D"
    />
  </Icon>
);
