import { Box, IconButton, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';
import { Adjust } from '../../Icons/Adjust';
import { TextIcon } from '../../Icons/Text';
import { Bin } from '../../Icons/Bin';
import React from 'react';
import { Iframe } from '../../Icons/Iframe';
import { UploadCircle } from '../../Icons/UploadCircle';
import { Upload } from '../../Icons/Upload';

interface ExperienceItemAdjustMenuProps {
  isOpen: boolean;
  isPublished: boolean;
  deleteExperience: () => void;
  renameExperience: () => void;
  unPublishExperience: () => void;
  openEmbedModal: () => void;
  setOpen: (state: boolean) => void;
  updateSourceMaterials: () => void;
}

function ExperienceItemAdjustMenu({
  isOpen,
  isPublished,
  renameExperience,
  deleteExperience,
  unPublishExperience,
  openEmbedModal,
  setOpen,
  updateSourceMaterials,
}: ExperienceItemAdjustMenuProps) {
  return (
    <Menu placement="right" closeOnBlur closeOnSelect isLazy isOpen={isOpen}>
      <MenuButton
        border="#D3BB8A"
        as={IconButton}
        aria-label="View Experience"
        bg="white"
        _hover={{
          bg: '#D3BB8A',
        }}
        borderRadius="100%"
        icon={<Adjust boxSize={[5]} stroke="#08101D" />}
        variant="outline"
        onClick={() => setOpen(!isOpen)}
      />
      <MenuList
        borderRadius="16px"
        background="#FFF"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        backdropFilter="blur(12px)"
        padding={['16px']}
        width={['230px']}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        position="relative"
        gap={2}
      >
        {isPublished && (
          <Box
            width={['100%']}
            height={['25px']}
            padding={['0px 8px']}
            borderRadius="30px"
            transition="0.2s all"
            _hover={{
              bg: '#D6DBE4',
            }}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            onClick={openEmbedModal}
          >
            <Iframe boxSize={[4]} stroke="#08101D" />
            <Text ml={['5px']} fontFamily="Normal" fontSize={['12px']} color="black">
              Embed Experience
            </Text>
          </Box>
        )}
        <Box
          width={['100%']}
          height={['25px']}
          padding={['0px 8px']}
          borderRadius="30px"
          transition="0.2s all"
          _hover={{
            bg: '#D6DBE4',
          }}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          onClick={renameExperience}
        >
          <TextIcon boxSize={[4]} stroke="#08101D" />
          <Text ml={['5px']} fontFamily="Normal" fontSize={['12px']} color="black">
            Rename
          </Text>
        </Box>
        <Box
          width={['100%']}
          height={['25px']}
          padding={['0px 8px']}
          borderRadius="30px"
          transition="0.2s all"
          _hover={{
            bg: '#D6DBE4',
          }}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          onClick={updateSourceMaterials}
        >
          <UploadCircle stroke="#08101D" boxSize={[4]} />
          <Text ml={['5px']} fontFamily="Normal" fontSize={['12px']} color="black">
            Update Source Materials
          </Text>
        </Box>
        <Box
          width={['100%']}
          height={['25px']}
          padding={['0px 8px']}
          borderRadius="30px"
          transition="0.2s all"
          _hover={{
            bg: '#D6DBE4',
          }}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          onClick={deleteExperience}
        >
          <Bin stroke="#08101D" boxSize={[4]} />
          <Text ml={['5px']} fontFamily="Normal" fontSize={['12px']} color="black">
            Delete
          </Text>
        </Box>
        {isPublished && (
          <Box
            width={['100%']}
            height={['25px']}
            padding={['0px 8px']}
            borderRadius="30px"
            transition="0.2s all"
            _hover={{
              bg: '#D6DBE4',
            }}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            onClick={unPublishExperience}
          >
            <Upload stroke="#08101D" boxSize={[4]} />
            <Text ml={['5px']} fontFamily="Normal" fontSize={['12px']} color="black">
              Unpublish
            </Text>
          </Box>
        )}
      </MenuList>
    </Menu>
  );
}

export default ExperienceItemAdjustMenu;
