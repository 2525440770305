import { Box, Button, FormControl, FormErrorMessage, Input, Spinner, useToast } from '@chakra-ui/react';
import FormWrapper from '../common/FormWrapper';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useAuthStore } from '../../store/auth';
import configureAxios from '../../axiosClient';
import { useUserStore } from '../../store/user';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { dispatchUserToAnalytics } from '../../analytics';

type CreateAccountFormValues = {
  name: string;
  surName: string;
};

const axiosClient = configureAxios();

function CreateAccountForm() {
  const { isLoggedIn, setFirstTime } = useAuthStore();
  const { user, getUser, setUser, organisation } = useUserStore();

  useEffect(() => {
    isLoggedIn && getUser();
  }, [isLoggedIn]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<CreateAccountFormValues>();
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const inviteId = params.get('inviteId');
  const { setLoggedIn, setActiveLoginView } = useAuthStore();

  const [createAccountLoading, setCreateAccountLoading] = useState(false);

  const onSubmit: SubmitHandler<CreateAccountFormValues> = (data) => {
    setCreateAccountLoading(true);

    axiosClient
      .post(`user/profile/update`, {
        name: data.name,
        surname: data.surName,
      })
      .then((res) => {
        const isStatusOk = res.data.status === 'OK';
        setCreateAccountLoading(false);

        if (isStatusOk) {
          setUser({
            ...user,
            firstName: data.name,
            lastName: data.surName,
          });

          dispatchUserToAnalytics('sign_up', {
            organisation_user_id: user.id,
            organisation_id: organisation.organizationId,
          });
          setValue('name', '');
          setValue('surName', '');

          if (inviteId) {
            axiosClient
              .post(`user/invites/${inviteId}/accept`)
              .then((res) => {
                const isStatusOk = res?.data.status === 'OK';
                if (isStatusOk) {
                  setLoggedIn(true);
                  setFirstTime(true);
                  setTimeout(() => {
                    setActiveLoginView('login');
                  }, 1000);
                }
              })
              .catch((err: unknown) => {
                console.log(err);
              });
          } else {
            setActiveLoginView('questionnaire');
          }
        } else {
          toast({
            title: `There was an error logging in`,
            status: 'error',
          });
        }
      })
      .catch((err: unknown) => {
        console.log(err);
      });
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)} name="sing_up">
      {createAccountLoading ? (
        <Box w={['100%']} height={['100px']} display="flex" justifyContent="center" alignItems="center">
          <Spinner size="md" />
        </Box>
      ) : (
        <Box
          w={['100%']}
          height={['auto']}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          gap={5}
        >
          <FormControl isInvalid={errors.name ? true : false} mb="10px">
            <Input
              id="name"
              placeholder="enter your name"
              color="white"
              borderBottom="1px solid #D6DBE4"
              variant="flushed"
              fontFamily="Normal"
              fontSize={['14px', '14px', '14px']}
              size="md"
              _placeholder={{ color: '#7F8B9E' }}
              errorBorderColor="#EA1A4C"
              {...register('name', {
                required: 'This is required',
                minLength: { value: 4, message: 'Minimum length should be 4' },
                maxLength: { value: 20, message: 'maximum length should be 20' },
              })}
            />
            <FormErrorMessage color="#EA1A4C" mt={['4px']} fontSize="13px" fontFamily="Normal">
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.surName ? true : false} mb="10px">
            <Input
              id="surName"
              placeholder="enter your surname"
              color="white"
              borderBottom="1px solid #D6DBE4"
              variant="flushed"
              fontFamily="Normal"
              fontSize={['14px', '14px', '14px']}
              size="md"
              _placeholder={{ color: '#7F8B9E' }}
              errorBorderColor="#EA1A4C"
              {...register('surName', {
                required: 'This is required',
                minLength: { value: 4, message: 'Minimum length should be 4' },
                maxLength: { value: 20, message: 'maximum length should be 20' },
              })}
            />
            <FormErrorMessage color="#EA1A4C" mt={['4px']} fontSize="13px" fontFamily="Normal">
              {errors.surName && errors.surName.message}
            </FormErrorMessage>
          </FormControl>
          <Button
            textTransform="uppercase"
            width={['80%', '80%', '300px']}
            variant="outline"
            color="#7F8B9E"
            background="#1D2531"
            border="1px solid rgba(242, 243, 245, 0.14)"
            fontFamily="Metal"
            fontSize={['14px', '14px', '15px']}
            borderRadius="30px"
            _hover={{
              bg: 'white',
              color: '#08101D',
            }}
            isLoading={isSubmitting}
            type="submit"
          >
            Next
          </Button>
        </Box>
      )}
    </FormWrapper>
  );
}

export default CreateAccountForm;
