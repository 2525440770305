// Theming
import { ChakraProvider } from '@chakra-ui/react';
import { CustomTheme } from './theme';

// Library
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

// Styles

// Views
import Overlay from './components/common/Overlay';
import Auth from './pages/Auth';
import ExperiencesView from './pages/ExperiencesView';
import ProcessesView from './pages/ProcessesView';
import PluginsView from './pages/PluginsView';
import BillingView from './pages/BillingView';
import Viewer from './pages/Viewer';

// Interfaces
import { useAuthStore } from './store/auth';
import { AuthorizedRoute } from './AuthorizedRoute';
import InternalRedirect from './pages/InternalRedirect';
import { useUserStore } from './store/user';
import usePostHog from './custom-hooks/usePosthog';
import Cookies from 'js-cookie';
import axios from 'axios';

const App = () => {
  const location = useLocation();
  const user = useUserStore((state) => state.user);
  const organisation = useUserStore((state) => state.organisation);
  const { identifyUser } = usePostHog();

  const { isLoggedIn, tokenActive, setTokenActive, setLoggedIn } = useAuthStore((state) => state);
  useEffect(() => {
    const accessToken = Cookies.get('access_token');
    if (accessToken) {
      setTokenActive(true);
    } else {
      setTokenActive(false);
      axios
        .get(process.env.REACT_APP_API_URL + 'auth/refresh', {
          withCredentials: true,
        })
        .then(() => {
          setTokenActive(true);
        })
        .catch(() => {
          setTokenActive(false);
          const { resetUser } = useUserStore.getState();
          const { resetAuth } = useAuthStore.getState();
          resetAuth();
          resetUser();
          setLoggedIn(false);
        });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && user && user.id && organisation && organisation.organizationId) {
      identifyUser(`User_${user.id}_org_${organisation.organizationId}`, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      });
    }
  }, [location, user, organisation]);

  return (
    <ChakraProvider theme={CustomTheme}>
      {isLoggedIn && tokenActive ? (
        <Overlay>
          <Routes>
            <Route path="/" element={<ExperiencesView />} />
            <Route path="/plugins" element={<PluginsView />} />
            <Route
              path="/billing"
              element={
                <AuthorizedRoute>
                  <BillingView />
                </AuthorizedRoute>
              }
            />
            <Route path="/processes" element={<ProcessesView />} />
            <Route path="/viewer/:experienceId" element={<Viewer />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </Overlay>
      ) : (
        <Routes>
          <Route path="/login" element={<Auth />} />
          <Route path="/internal" element={<InternalRedirect />} />
          <Route path="/*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </ChakraProvider>
  );
};

export default App;
